// import { ReactComponent as TextIcon } from './assets/etc-icons/icon_text_edit.svg';
// import { ReactComponent as SMSIcon } from './assets/etc-icons/icon_sms_edit.svg';
// import { ReactComponent as DocumentIcon } from './assets/etc-icons/icon_document-text.svg';
// import { ReactComponent as CodetIcon } from './assets/etc-icons/icon_code.svg';
// import { ReactComponent as SummaryIcon } from './assets/etc-icons/icon_summary.svg';
// import { ReactComponent as BookIcon } from './assets/etc-icons/icon_book.svg';

export const Constants = {
  app: {
    name: 'Copilot',
    updateCheckIntervalSeconds: 60 * 5,
    CONNECTION_ALERT_ID: 'connection-alert',
    importTypes: '.txt,.pdf,.docx,.md,.jpg,.jpeg,.png,.tif,.tiff,.bmp,.gif,.svg',
  },
  msal: {
    method: 'redirect', // 'redirect' | 'popup'
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
    semanticKernelScopes: ['openid', 'offline_access', 'profile'],
    // MS Graph scopes required for loading user information
    msGraphAppScopes: ['User.ReadBasic.All'],
  },
  bot: {
    profile: {
      id: 'bot',
      fullName: 'Copilot',
      emailAddress: '',
      photo: '',
    },
    fileExtension: 'skcb',
    typingIndicatorTimeoutMs: 5000,
  },
  debug: {
    root: 'sk-chatbot',
  },
  sk: {
    service: {
      defaultDefinition: 'int',
    },
    // Reserved context variable names
    reservedWords: ['server_url', 'server-url'],
    // Flag used to indicate that the variable is unknown in plan preview
    UNKNOWN_VARIABLE_FLAG: '$???',
  },
  adoScopes: ['vso.work'],
  BATCH_REQUEST_LIMIT: 20,
  plugins: {
    // For a list of Microsoft Graph permissions, see https://learn.microsoft.com/en-us/graph/permissions-reference.
    // Your application registration will need to be granted these permissions in Azure Active Directory.
    msGraphScopes: ['Calendars.Read', 'Mail.Read', 'Mail.Send', 'Tasks.ReadWrite', 'User.Read'],
  },
  KEYSTROKE_DEBOUNCE_TIME_MS: 300,
  prompt: [
    {
      id: 0,
      name: '맞춤법 검사기',
      tag: '글쓰기',
      input:
        '당신은 고도로 전문적인 한국어 작가야. 당신의 역할은 제가 입력한 한국어 텍스트에서 문법 오류를 찾아내는 것이야. 답안은 세 부분으로 구성되어야 해. 첫 번째 부분에서는 텍스트에서 발견한 모든 문법 오류를 대괄호로 묶어야 해. 다음으로, 첫 번째 문단 아래 단락에서 각 오류를 대체할 문법적으로 올바른 단어나 구를 제공해야 해. 마지막으로 수정된 문장 전체를 입력해. 대괄호 안에 수정 내용을 입력해. 자연스러운 한국어로 답변해줘.',
      aiModel: 'GPT-4o',
      icon: 'etc-icons/icon_text_edit.svg',
    },
    {
      id: 1,
      name: '오피셜 메일 작성',
      tag: '메일',
      input:
        '한국의 이메일 형식에 맞춰, 이메일을 작성해줘.  발신자와 수신자의 관계를 고려해. 공손하고 포멀한 말투를 구사해.   [[발신인: 팀원]] [[수신인: 팀원 전체]] [[핵심 내용: 본부 체육행사 안내 공지 ]]',
      aiModel: 'GPT-3.5',
      icon: 'etc-icons/icon_sms_edit.svg',
    },
    {
      id: 2,
      tag: '파워포인트',
      name: '파워포인트 작성을 한번에!',
      input:
        '너는 공부를 잘하는 A+ [[역할:중학생/고등학생/대학생/대학원생/일반인]] 역할을해. [[주제:AI와 미래]] 에 대한 PowerPoint 슬라이드와 스크립트를 만들어. 너의 청중은 [[예상청중:동급생/선생님/교수님/일반인]] 이야. 발표는 다양한 정보를 제공하고 흥미롭고 설득력 있어야해. 각 슬라이드에는 간단한 제목을 넣어. 슬라이드 내용은 Short Phrase로 구성해. Bulleted points 를 사용해서 자세하게 써. 각 슬라이드 끝에는 스크립트를 따로 써. 너의 발표는 약 [[발표시간:10분]] 이야. 약 [[슬라이드장 수:8장]] 의 슬라이드를 만들어줘. 모든 대답은 한국어로 작성해.',
      aiModel: 'GPT-3.5',
      icon: 'etc-icons/icon_document-text.svg',
    },
    {
      id: 3,
      tag: '코드',
      name: '신입개발자의 코드 질문',
      input:
        '너는 웹 프로그래머 시니어 개발자야. 지금부터 물어보는 질문에 대한 대답을 할거야. 다음 순서에 따라 대답을 적어줘. 1. 신입 개발자가 이해할 수 있는 수준으로 핵심을 먼저 말해줘. 2. 핵심을 바탕으로 한단계 더 세부적인 내용을 말해줘. 예를 들면, 장단점을 비교해줄 수도 있고, 하위 카테고리에 있는 내용을 설명해줄 수도 있어. 3. 시니어 개발자만이 볼 수 있는 인사이트를 말해줘. 예를 들면, 해당 개념을 적용했을 때 생길 수 있는 문제점이라던지, 아니면 더 공부하면 좋을 것 같은 키워드라든지, 주의해야할점, 더 생각해봐야할점 등등을 정리해줘. 4. 마지막으로, 답변에서 나왔던 키워드들을 표로 만들어줘. 신입개발자가 "[[질문]] "를 질문했는데 대답해줄 수 있어? (참고로 신입 개발자는 react, typescript 를 사용하여 프론트엔드 개발을 하는 사람이야. 아직 백엔드 지식이나 computer science에 대한 개념은 부족해)',
      aiModel: 'GPT-3.5',
      icon: 'etc-icons/icon_code.svg',
    },
    {
      id: 4,
      tag: '요약',
      name: '토픽 중심 요약',
      input:
        'Topic-based summarization 을 할 거야. 주어진 텍스트에서 구체적인 토픽과 테마를 추출해. 글에 있는 모든 중요한 정보를 요약해. 단문의 형태로 다음의 형식으로 답해. 답은 최소 5줄 이내로 해줘.   1. 키워드:  2. 중심 내용: (1) (2) (3) (4) (5) [[텍스트: 요약할 텍스트 입력]]',
      aiModel: 'GPT-3.5',
      icon: 'etc-icons/icon_summary.svg',
    },
    {
      id: 5,
      tag: '번역',
      name: '자연스러운 영한 번역',
      input:
        '너는 번역 전문가야. 여러가지 유형의 영어 텍스트를 한국어로 번역해. 규칙1. Please retain some special terms such as Pronoun, Name and technical jargon. 규칙 2. Use the most appropriate words 아주 자연스러운 한국어를 사용해줘.',
      aiModel: 'GPT-4o',
      icon: 'etc-icons/icon_book.svg',
    },
  ],
  tabListItems: [
    {
      key: 'All',
      label: '전체',
    },
    {
      key: 'Bookmark',
      label: '즐겨찾기',
    },
    {
      key: 'MyPlugin',
      label: '내플러그인',
    },
  ],

  orderbyList: [
    {
      value: 'UpdatedTime',
      label: '최신순',
    },
    {
      value: 'BookmarkUserCount',
      label: '즐겨찾기순',
    },
  ],
  companyCode: '1014',
};
