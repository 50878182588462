import { useState, useEffect, useRef } from 'react';
import { Icon } from "../Icon";
import { IconButton } from './IconButton';

interface AccordionProps {
    defaultValue:string[];
    data: AccordionData[];
    isMultiple: boolean;
    onChange: (value: string[]) => void;
}

interface AccordionData {
    label: string | React.ReactNode;
    icon: string;
    content: React.ReactNode;
    value: string;
}

type OpenItems = Record<string, boolean>;

interface AccordingItemProps {
    label: string | React.ReactNode;
    content: React.ReactNode;
    isOpen: boolean;
    icon: string;
    onClick: () => void;
}
  

const AccordionItem: React.FC<AccordingItemProps> = ({ label, icon, content, isOpen, onClick }) => {
  const [render, setRender] = useState(isOpen);
  const itemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = itemRef.current;
        const handleAnimationEnd = () => {
          if (!isOpen) {
            setRender(false);
          }
        };
    
        if (container) {
          container.addEventListener('transitionend', handleAnimationEnd);
          if (isOpen) {
            setRender(true);
          }
        }
    
    
    return () => {
        if (container) {
          container.removeEventListener('transitionend', handleAnimationEnd);
        }
      };
  }, [isOpen]);

  return (
    <div ref={itemRef} className="dsx-Accordion-item" data-state={isOpen ? "active" : "inactive"}>
      <div className="dsx-Accordion-header">
        <button 
          type="button"
          className="dsx-Accordion-control"
          aria-expanded={isOpen}
          onClick={onClick}
        >
          { icon && <Icon name={icon} />}
          <span className="accordion-title">{label}</span>
          <IconButton name="chevronBottom" >펼치기</IconButton>
        </button>
      </div>
      {render && (
        <div className="dsx-Accordion-container">
          <div className="dsx-Accordion-content">
            {content}
          </div>
        </div>
      )}
    </div>
  );
};


export const Accordion: React.FC<AccordionProps> = ({ defaultValue, data, isMultiple = false, onChange }) => {
  const [openItems, setOpenItems] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (!defaultValue) {
      return;
    }
    const initialOpenItems: OpenItems = {};
    defaultValue.forEach(value => {
      initialOpenItems[value] = true;
    });
    setOpenItems(initialOpenItems);
  }, [defaultValue]);

  const toggleItem = (value: string) => {
    setOpenItems(prev => {
      const newOpenItems = isMultiple ? { ...prev, [value]: !prev[value]} : { [value]: !prev[value] };
      const openValues = Object.keys(newOpenItems).filter(key => newOpenItems[key]);
      if (onChange) {
        onChange(openValues);
      }
      return newOpenItems;
    });
  };

  return (
    <div className="dsx-Accordion">
      {data.map((item) => (
        <AccordionItem
          key={item.value}
          label={item.label}
          icon={item.icon}
          content={item.content}
          isOpen={openItems[item.value] || false}
          onClick={() => {toggleItem(item.value)}}
        />
      ))}
    </div>
  );
};
