import { PromptService } from '../services/PromptService';
import { useAppDispatch } from '../../redux/app/hooks';
import { setCategoryModel } from '../../redux/features/studio/categorySlice';
import { IPluginPromptData, IPluginPromptParam } from '../models/studio/PluginPromptList';

export const usePrompt = () => {
  const dispatch = useAppDispatch();
  const promptservice = new PromptService();

  const getAllPrompt = async () => {
    const result = await promptservice.getAllPromptAsync();
    return result;
  };

  // 프롬프트 카테고리 목록 조회
  const getPromptCategory = async () => {
    const result = await promptservice.getPromptCategoryAsync().then((res) => {
      const categoryList = res.data;

      const list: Array<{ value: string; label: string }> = categoryList.map((item) => ({
        value: item.id,
        label: item.categoryName,
      }));
      // const exists = list.some((item) => item.value === 'all' && item.label === '전체');

      // if (!exists) {
      //   list.unshift({ value: 'all', label: '전체' });
      // }

      dispatch(setCategoryModel(list));

      return true;
    });
    return result;
  };

  // 프롬프트 리스트 조회
  const getPromptList = async (obj: IPluginPromptParam) => {
    const result = await promptservice.getPromptListAsync(obj);
    return result;
  };

  // 플러그인 즐겨찾기 조회
  const getPromptBookmark = async (obj: IPluginPromptParam) => {
    const result = await promptservice.getPromptBookmarkAsync(obj);
    return result;
  };

  //프롬프트 키워드 검색
  const getPromptSearch = async (obj: IPluginPromptParam) => {
    const result = await promptservice.getPromptSearchAsync(obj);
    return result;
  };

  // 내 플러그인 조회
  const getMyPromptList = async (obj: IPluginPromptParam) => {
    const result = await promptservice.getMyPromptListAsync(obj);
    return result;
  };

  // [POST] 프롬프트 생성
  const postPrompt = async (obj: IPluginPromptData) => {
    const result = await promptservice.postPromptAsync(obj);
    return result;
  };

  // [DELETE] 프롬프트 삭제
  const deletePrompt = async (obj: IPluginPromptData) => {
    const result = await promptservice.deletePromptAsync(obj);
    return result;
  };

  // [GET] 프롬프트 상세 조회
  const getPrompt = async (promptId: string) => {
    const result = await promptservice.getPromptAsync(promptId);
    return result;
  };

  // [PUT] 프롬프트 수정
  const putPrompt = async (obj: IPluginPromptData) => {
    const result = await promptservice.putPromptAsync(obj);
    return result;
  };

  // [POST] 프롬프트 북마크(즐겨찾기) 수정
  const postPromptBookmark = async (promptId: string) => {
    const result = await promptservice.postPromptBookmarkAsync(promptId);
    return result;
  };

  // [DELETE] 프롬프트 북마크(즐겨찾기) 삭제
  const deletePromptBookmark = async (promptId: string) => {
    const result = await promptservice.deletePromptBookmarkAsync(promptId);
    return result;
  };

  // [GET] 오늘 등록 갯수
  const getPromptTodayCount = async (createdStatus: number) => {
    const result = await promptservice.getPromptTodayCountAsync(createdStatus);
    return result;
  };

  // [GET] DALLE 이미지 생성
  const getDalleImage = async (prompt: string) => {
    const result = await promptservice.getDalleImg(prompt);
    return result;
  };

  // [POST] 이미지 업로드
  const postProfileImg = async (customPluginId: string, profile: File) => {
    const formData = new FormData();
    formData.append('profile', profile); // profile 필드에 파일 추가

    const result = await promptservice.postProfileImg(customPluginId, formData);
    return result;
  };

  // [POST] 프롬프트 임시저장
  const postPromptDraft = async (obj: IPluginPromptData) => {
    const result = await promptservice.postPromptDraftAsync(obj);
    return result;
  };

  // [POST] 임시저장된 상태에서 아무것도 변경되지 않고 상태만 등록으로 변경
  const postPromptStatus = async (obj: IPluginPromptData) => {
    const result = await promptservice.postPromptStatusAsync(obj);
    return result;
  };

  return {
    getAllPrompt,
    getPromptCategory,
    getPromptList,
    getPromptBookmark,
    getPromptSearch,
    getMyPromptList,
    postPrompt,
    deletePrompt,
    putPrompt,
    getPrompt,
    postPromptBookmark,
    deletePromptBookmark,
    getPromptTodayCount,
    getDalleImage,
    postProfileImg,
    postPromptDraft,
    postPromptStatus,
  };
};
