import React from "react";
import { Accordion } from "../../../base/Accordion";
import { Badge } from "../../../base";
import { Carousel } from "../../../base/Carousel";
import { IChatMessageSearchQuery, IChatMessageWebSearchResult } from "../../../../libs/models/conversations/ChatMessage";
import { WebSearchSource, webSearchTool } from "../../../../libs/models/app/Plugin";

export const SearchLoadingState: React.FC<{ search?: IChatMessageWebSearchResult, queries?: IChatMessageSearchQuery[] }> = ({ search, queries }) => {

    const webSearchSource = search?.sourceType ? webSearchTool[search?.sourceType] : webSearchTool[WebSearchSource.WebSearch];
    const showImage = webSearchSource.source == WebSearchSource.WebSearch;
    //const defaultOpen = [webSearchTool[WebSearchSource.WebSearch].value];

    const Loading2 = (<React.Fragment>
        <div className="search-result">
            <div className="search-keyword">
                {webSearchSource.source !== WebSearchSource.Memory && queries?.map((search, i) => (
                    <Badge
                        key={i}
                        prefixIcon={'loading'}
                        className='keyword'
                        size="large"
                        accent="primary"
                        variant="tint"
                    >
                        {search.query}</Badge>
                ))}
            </div>
            {<div className={"search-contents"}>
                <Carousel slides={[0, 1, 2, 3, 4].map((i) =>
                    <div className={`contents-card is-skeleton ${webSearchSource.source.toLowerCase()}`} key={i} />
                )} slidesPerView={webSearchSource.previewCount} variant='fill' />
            </div>}
            {showImage && <div className="">
                <div className="search-img ">
                    <div className="image-item is-skeleton" />
                    <div className="image-item is-skeleton" />
                    <div className="image-item is-skeleton" />
                    <div className="image-item is-skeleton" />
                    <div className="image-item is-skeleton" />

                </div>
            </div>}
        </div>
    </React.Fragment>)

    return (
        <>
            <div className="websearch-result">
                <div className={`result-card`}>
                    <Accordion
                        data={[
                            {
                                content: Loading2,
                                icon: webSearchSource.icon,
                                label: webSearchSource.label,
                                value: webSearchSource.value
                            }
                        ]}
                        isMultiple={false}
                        defaultValue={[webSearchSource.value]}
                        onChange={() => { }}
                    />
                </div>
            </div>

        </>
    )
};