// /components/multi-search.tsx
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Badge, Button, ButtonArea, Dialog, Heading, Link, Text } from '../../../base';
import { Accordion } from '../../../base/Accordion';
import { Card } from '../../../base/Card';
import { Carousel } from '../../../base/Carousel';
import { SearchLoadingState } from './SearchLoadingState';
import {
  IChatMessageReference,
  IChatMessageSearchImageReference,
  IChatMessageSearchQuery,
  IChatMessageWebSearchResult,
} from '../../../../libs/models/conversations/ChatMessage';
import { WebSearchSource, webSearchTool } from '../../../../libs/models/app/Plugin';
import { AcademicResultCard } from './AcademicSearch';
import { YouTubeResultCard } from './YoutubeSearch';
import { Tooltip } from '../../../Tooltip';
import { checkMobileKate } from '../../../utils/common';
import favicon from '../../../../assets/etc-icons/icon_plugin_ai_search_2.svg';
import noImage from '../../../../assets/etc-icons/img_notfound.png';
import classNames from 'classnames';

export interface SearchImage {
  url: string;
  //description: string;
}

export interface SearchResult {
  url: string;
  title: string;
  content: string;
  raw_content: string;
  published_date?: string;
}

export interface SearchQueryResult {
  query: string;
  results: SearchResult[];
  images: SearchImage[];
}

export interface MultiSearchResponse {
  searches: SearchQueryResult[];
}

export interface MultiSearchArgs {
  queries: string[];
  maxResults: number[];
  topics: Array<'general' | 'news'>;
  searchDepth: Array<'basic' | 'advanced'>;
}
const MemoryResultCard = ({ reference }: { reference: IChatMessageReference }) => (
  <div className="contents-card memory">
    <Card variant="vertical">
      <React.Fragment>
        <div className="tit">
          <Tooltip position={['bottom', 'right']} theme="inverse" message={reference.title}>
            <Heading size="body1" className="card-header">
              <div className="header-content">{reference.title}</div>
            </Heading>
          </Tooltip>
        </div>
        <Text className="card-text" accent="alternative" size="caption1">
          {reference.content}
        </Text>
      </React.Fragment>
    </Card>
  </div>
);

const ResultCard = ({ reference }: { reference: IChatMessageReference }) => (
  <div className="contents-card web">
    <Card variant="vertical">
      <React.Fragment>
        <Tooltip position={['bottom', 'right']} theme="inverse" message={reference.title}>
          <div className="card-header">
            <img
              src={`https://www.google.com/s2/favicons?sz=6&domain=${new URL(reference.uri).hostname}`}
              onError={(e) => {
                e.currentTarget.src = favicon;
              }}
            />
            <div className="header">
              <Heading size="body1" className="header-content">
                {reference.title}
              </Heading>

              <Text className="card-text" size="caption2">
                <Link href={reference.uri} suffixIcon="link">
                  {' '}
                  {new URL(reference.uri).hostname}
                </Link>
              </Text>
            </div>
          </div>
        </Tooltip>
        <Text className="card-text" accent="alternative" size="caption1">
          {reference.content}
        </Text>
      </React.Fragment>
    </Card>
  </div>
);
const MemoryContents: React.FC<{ sourceType: WebSearchSource; references: IChatMessageReference[]; }> = ({
  sourceType,
  references,
}) => {
  const data = references?.map((reference, index) => (
    <React.Fragment key={`${index}`}>
        <motion.div
          key={`${index}`}
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3, delay: index * 0.1 }}
          style={{ width: 'inherit' }}
        >
          <MemoryResultCard reference={reference} />
        </motion.div>
    </React.Fragment>
  ));

  // Collect all images from all searches

  return (
    <div className="search-result">
      {/* <div className="search-keyword">
        {args?.map((search, i) => (
          <Badge key={i} prefixIcon={'search'} className="keyword" size="large" accent="primary" variant="tint">
            {search.query}
          </Badge>
        ))}
      </div> */}
      <div className="search-contents">
        <Carousel slides={data} slidesPerView={webSearchTool[sourceType].previewCount} variant="fill" />
      </div>
    </div>
  );
};

const SearchContents: React.FC<{
  sourceType: WebSearchSource;
  references: IChatMessageReference[];
  args?: IChatMessageSearchQuery[];
}> = ({ sourceType, references, args }) => {
  const [isDesktop, setIsDesktop] = useState(!checkMobileKate() || window.innerWidth <= 768);
  useEffect(() => {
    setIsDesktop(window.innerWidth > 768);
  }, []);
  const data = references?.map((reference, index) => (
    <motion.div
      key={`${reference.index}`}
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.3, delay: reference.index * 0.1 }}
      style={{ width: 'inherit' }}
    >
      {sourceType == WebSearchSource.Academic ? (
        <AcademicResultCard academic={reference} />
      ) : sourceType == WebSearchSource.YouTube ? (
        <YouTubeResultCard video={reference} />
      ) : (
        <ResultCard reference={reference} key={index} />
      )}
    </motion.div>
  ));

  // Collect all images from all searches

  return (
    <div className="search-result">
      <div className="search-keyword">
        {args?.map((search, i) => (
          <Badge key={i} prefixIcon={'search'} className="keyword" size="large" accent="primary" variant="tint">
            {search.query}
          </Badge>
        ))}
      </div>
      <div className="search-contents">
        <Carousel slides={data} slidesPerView={isDesktop ? webSearchTool[sourceType].previewCount : 1} variant="fill" />
      </div>
    </div>
  );
};

const AccordionLabel: React.FC<{ label: string; text?: string }> = ({ label, text }) => (
  <div className="card-label">
    {label}
    {text && (
      <Badge className="result-badge" size="medium" accent="normal" variant="tint">
        {text}
      </Badge>
    )}
  </div>
);
const ImageGrid: React.FC<{ images: IChatMessageSearchImageReference[] }> = ({ images }) => {
  const [isDesktop, setIsDesktop] = useState(!checkMobileKate() || window.innerWidth <= 768);
  useEffect(() => {
    setIsDesktop(window.innerWidth > 768);
  }, []);

  const [isImageOpen, setIsImageOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState<number>(0);

  const displayImages = images.slice(0, isDesktop ? 5 : 4);
  const hasMore = images.length > (isDesktop ? 5 : 4);
  const NOIMAGE_DEC = '사내 네트워크 정책으로 인해 오류가 발생했어요.';
  const [errorImages, setErrorImages] = useState<Set<number>>(new Set());

  return (
    <div>
      <div className="search-img">
        {displayImages.map((image, index) => (
          <motion.button
            key={index}
            className={'image-item'}
            onClick={() => {
              setSelectedImage(index);
              setIsImageOpen(true);
            }}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
          >
            <img
              src={image.url}
              alt={image.description}
              className={''}
              onLoad={(e) => {
                e.currentTarget.classList.add('loaded');
              }}
              onError={(e) => {
                e.currentTarget.classList.add('error');
                e.currentTarget.src = noImage;
                e.currentTarget.alt = NOIMAGE_DEC;
                setErrorImages((prev) => new Set(prev).add(index));
              }}
            />
            {(image.description ?? errorImages.has(index)) && (
              <div className="image-description">
                <Text size="caption2">{errorImages.has(index) ? NOIMAGE_DEC : image.description}</Text>
              </div>
            )}
            {hasMore && index === displayImages.length - 1 && (
              <div className="image-more">
                <span className="text-sm font-medium text-white">+{images.length - displayImages.length}</span>
              </div>
            )}
          </motion.button>
        ))}
        <Dialog
          title="이미지"
          onClose={() => {
            setIsImageOpen(false);
          }}
          closeOnOutSide={true}
          size="full"
          isOpen={isImageOpen}
          className="image-viewer"
          buttons={
            <ButtonArea align="full">
              <Button
                name="prev"
                size="large"
                variant="secondary"
                iconOnly="prev"
                onClick={() => {
                  setSelectedImage((prev) => (prev === 0 ? images.length - 1 : prev - 1));
                }}
              >
                이전
              </Button>
              <Button
                name="next"
                size="large"
                variant="secondary"
                iconOnly="next"
                onClick={() => {
                  setSelectedImage((prev) => (prev === images.length - 1 ? 0 : prev + 1));
                }}
              >
                다음
              </Button>
            </ButtonArea>
          }
        >
          <div className="viewer-container">
            <div className="image-scroller">
              <img
                alt={images[selectedImage].description}
                src={images[selectedImage].url}
                onError={(e) => {
                  e.currentTarget.src = noImage;
                  e.currentTarget.alt = NOIMAGE_DEC;
                }}
              />
            </div>

            <Text size="body3" className="description">
              {errorImages.has(selectedImage) ? NOIMAGE_DEC : images[selectedImage].description}
            </Text>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

const MultiSearch: React.FC<{
  results: IChatMessageWebSearchResult;
  args?: IChatMessageSearchQuery[];
  isAccordionOpen?: boolean;
}> = ({ results, args, isAccordionOpen }) => {
  const [isOpen, setIsOpen] = React.useState(results.references.length == 0 ? false : isAccordionOpen);
  // const [isDisable, setIsDisable] = React.useState(false);
  // const [isImageOpen, setIsImageOpen] = React.useState(false);
  // const [selectedImage, setSelectedImage] = React.useState<number>(0);
  if (!results) {
    return <SearchLoadingState search={results} />;
  }

  const onToggle = () => {
    setIsOpen(!isOpen);
  };
  // const displayImages = results.images
  //     ? results.images.map((url, index) => <img onClick={() => { setIsImageOpen(true); setSelectedImage(index) }} alt={url} src={url} style={{ height: '150px' }} key={index} />)
  //     : [];

  const resource = webSearchTool[results.sourceType ?? WebSearchSource.WebSearch];

  const Label =
    results.sourceType === WebSearchSource.Academic ? (
      <AccordionLabel label={resource.label} text={`Found ${results.references?.length} Papers`} />
    ) : results.sourceType === WebSearchSource.YouTube ? (
      <AccordionLabel label={resource.label} text={`${results.references?.length} videos`} />
    ) : (
      resource.label
    );

  const Contents =
    resource.source == WebSearchSource.Memory ? (
      <MemoryContents sourceType={resource.source} references={results.references} />
    ) : (
      <div>
        <SearchContents sourceType={resource.source} references={results.references} args={args} />
        {results.images?.length > 0 && <ImageGrid images={results.images} />}
      </div>
    );
  return (
    <div className="websearch-result">
      <div className={classNames('result-card', { disabled: results.references.length == 0 })}>
        <Accordion
          data={[
            {
              content: Contents,
              icon: resource.icon,
              label: Label,
              value: resource.value,
            },
          ]}
          isMultiple={true}
          defaultValue={[isOpen ? resource.value : '']}
          onChange={onToggle}
        />
      </div>
      {/* {displayImages.length > 0 && (
                <div className="search-img">
                    <Carousel slides={displayImages} slidesPerView={4} variant="fill" />
                </div>
            )} */}
      {/* <Dialog
                title='이미지'
                onClose={() => { setIsImageOpen(false) }}
                closeOnOutSide={false}
                size='full'
                isOpen={isImageOpen}
                className="image-viewer"
                buttons={<>
                    <IconButton name="prev" size="medium" onClick={() => { setSelectedImage(prev => prev === 0 ? results.images.length - 1 : prev - 1) }}>이전</IconButton>
                    <IconButton name="next" size="medium" onClick={() => { setSelectedImage(prev => prev === results.images.length - 1 ? 0 : prev + 1) }}>다음</IconButton>
                </>}
            >
                <img alt={results.images[selectedImage]} src={results.images[selectedImage]} />
            </Dialog> */}
    </div>
  );
};

export default MultiSearch;
