import { ChatMessageRole, IChatMessage } from '../../../libs/models/conversations/ChatMessage';
import { ChatHistoryItem } from './ChatHistoryItem';
import React from 'react';

interface ChatHistoryItemsProps {
  items: IChatMessage;
  messageIndex: number;
  botStatus: boolean;
  isLast: boolean;
  isTest?: boolean;
}

export const ChatHistoryItems: React.FC<ChatHistoryItemsProps> = ({
  items,
  messageIndex,
  botStatus,
  isLast,
  isTest,
}) => {
  const isMe = items.role === ChatMessageRole.User;
  const isBot = items.role === ChatMessageRole.System || items.role === ChatMessageRole.Assistant;
  const canRetry = isBot && isLast && items.responseFrom !== null;
  const isTyping = botStatus && isLast;

  const [currentItemId, setCurrentItemIndex] = React.useState(items.messages.length - 1);

  React.useEffect(() => {
    setCurrentItemIndex(items.messages.length - 1);
  }, [items.messages.length]);

  return (
    <ChatHistoryItem
      items={items}
      item={items.messages[currentItemId]}
      messageIndex={messageIndex}
      currentItemId={currentItemId}
      setCurrentItemIndex={setCurrentItemIndex}
      isMe={isMe}
      isBot={isBot}
      canRetry={canRetry}
      isTyping={isTyping}
      isTest={isTest}
    />
  );
};
