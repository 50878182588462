import { ExternalSource } from '../studio/PluginPromptList';

export interface PluginAPI {
  id: string;
  name: string;
  model: string | null;
  persona: string;
  description: string;
  profileUrl: string;
  isAvailable?: boolean;
  externalSource?: ExternalSource;
  tags?: string[];
  conversationStarters?: string[];
  hidden?: boolean;
}
export interface Plugin {
  pluginInfo: PluginAPI;
  enabled: boolean;
}

export type Plugins = Record<string, PluginAPI>;

export interface PluginsState {
  plugins: Plugins;
  selectedPlugin: PluginAPI | null;
}

export const enum BuiltInPlugins {
  Wendy = 'Plugin:Wendy',
  AiSearch = 'Plugin:AiSearch',
  KtdsAssistant = 'Plugin:KtdsAssistant',
}
export interface WebSearch {
  label: string;
  source: WebSearchSource;
  icon: string;
  value: string;
  previewCount: number;
  isShowMenu: boolean;
}

export const enum WebSearchSource {
  WebSearch = 'WebSearch',
  Academic = 'Academic',
  YouTube = 'YouTube',
  News = 'News',
  Memory = 'Memory',
}

export const webSearchTool: Record<string, WebSearch> = {
  WebSearch: {
    label: 'Web',
    source: WebSearchSource.WebSearch,
    icon: 'websearch',
    value: 'WebSearch',
    previewCount: 2,
    isShowMenu: true,
  },
  Academic: {
    label: 'Academic',
    source: WebSearchSource.Academic,
    icon: 'book',
    value: 'Academic',
    previewCount: 2,
    isShowMenu: true,
  },
  YouTube: {
    label: 'YouTube',
    source: WebSearchSource.YouTube,
    icon: 'youtube',
    value: 'YouTube',
    previewCount: 3,
    isShowMenu: true,
  },
  Memory: {
    label: '이런 자료를 참고했어요',
    source: WebSearchSource.Memory,
    icon: 'info',
    value: 'Memory',
    previewCount: 2,
    isShowMenu: false,
  },
  News: {
    label: 'Web',
    source: WebSearchSource.News,
    icon: 'websearch',
    value: 'WebSearch',
    previewCount: 2,
    isShowMenu: false,
  },
};

export const initialState: PluginsState = {
  plugins: {},
  selectedPlugin: null,
};
