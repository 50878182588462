import { usePlugin } from '../../libs/hooks/usePlugin'; // 플러그인 API 훅
import { EOpenScopeType, IPluginPromptData } from '../../libs/models/studio/PluginPromptList'; // 타입 정의
import { usePrompt } from './usePrompt';

interface UsePluginActions {
  handleMyChangeOpenScope: (menuType: string, id: string, openScope: EOpenScopeType) => void;
  handleAllBookmark: (menuType: string, id: string, bookmarked: boolean) => void;
  handleBookmaredkBookmark: (menuType: string, id: string, bookmarked: boolean) => void;
  handleAllDelete: (menuType: string, id: string) => void;
  handleBookmaredDelete: (menuType: string, id: string) => void;
  handleMyDelete: (menuType: string, id: string) => void;
  handleMyRemove: (menuType: string, id: string) => void;
  handleMyRestore: (menuType: string, id: string) => void;
  handleMyBookmark: (menuType: string, id: string, bookmarked: boolean) => void;
}

export function usePluginActions(
  listState: IPluginPromptData[],
  bookmarkState: IPluginPromptData[],
  myListState: IPluginPromptData[],
  setListState: React.Dispatch<React.SetStateAction<IPluginPromptData[]>>,
  setBookmarkState: React.Dispatch<React.SetStateAction<IPluginPromptData[]>>,
  setMyListState: React.Dispatch<React.SetStateAction<IPluginPromptData[]>>,
): UsePluginActions {
  const plugin = usePlugin();
  const prompt = usePrompt();

  /**
   * [Func] 삭제 이벤트
   */
  // const handleDelete = async (menuType: string, id: string) => {
  //   const copyState = { ...cardListState.find((item) => item.id === id), deleted: true };
  //   setCardListState((prevState) => prevState.map((item) => (item.id === id ? copyState : item)));
  //   menuType === 'plugin' ? await plugin.deletePlugin(copyState) : await prompt.deletePrompt(copyState);
  // };

  /**
   * [Func] 삭제 이벤트 - 전체 탭
   */
  const handleAllDelete = async (menuType: string, id: string) => {
    const copyState = { ...listState.find((item) => item.id === id) };
    if (copyState) {
      const updatedState = { ...copyState, deleted: true };
      setListState((prevState) => prevState.map((item) => (item.id === id ? updatedState : item)));
      menuType === 'plugin' ? await plugin.deletePlugin(updatedState) : await prompt.deletePrompt(updatedState);
    }
  };

  /**
   * [Func] 삭제 이벤트 - 즐겨찾기 탭
   */
  const handleBookmaredDelete = async (menuType: string, id: string) => {
    const copyState = { ...bookmarkState.find((item) => item.id === id) };
    if (copyState) {
      const updatedState = { ...copyState, deleted: true };
      setBookmarkState((prevState) => prevState.map((item) => (item.id === id ? updatedState : item)));
      menuType === 'plugin' ? await plugin.deletePlugin(updatedState) : await prompt.deletePrompt(updatedState);
    }
  };

  /**
   * [Func] 삭제 이벤트 - 내 탭
   */
  const handleMyDelete = async (menuType: string, id: string) => {
    const copyState = myListState.find((item) => item.id === id);
    if (copyState) {
      const updatedState = { ...copyState, deleted: true };
      setMyListState((prevState) => prevState.map((item) => (item.id === id ? updatedState : item)));

      menuType === 'plugin' ? await plugin.deletePlugin(updatedState) : await prompt.deletePrompt(updatedState);
    }
  };

  /**
   * [Func] 완전삭제 이벤트
   */
  // const handleRemove = async (menuType: string, id: string) => {
  //   const copyState = { ...cardListState.find((item) => item.id === id) };
  //   setCardListState((prevState) => prevState.filter((item) => item.id !== id));
  //   menuType === 'plugin' ? await plugin.deletePlugin(copyState) : await prompt.deletePrompt(copyState);
  // };

  /**
   * [Func] 완전삭제 이벤트 - 내 탭
   */
  const handleMyRemove = async (menuType: string, id: string) => {
    const copyState = myListState.find((item) => item.id === id);
    setMyListState((prevState) => prevState.filter((item) => item.id !== id));
    if (copyState) menuType === 'plugin' ? await plugin.deletePlugin(copyState) : await prompt.deletePrompt(copyState);
  };

  /**
   * [Func] 되돌리기 이벤트
   */
  // const handleRestore = async (menuType: string, id: string) => {
  //   const copyState = { ...cardListState.find((item) => item.id === id), deleted: false };
  //   setCardListState((prevState) => prevState.map((item) => (item.id === id ? copyState : item)));
  //   menuType === 'plugin' ? await plugin.postPlugin(copyState) : await prompt.putPrompt(copyState);
  // };
  /**
   * [Func] 되돌리기 이벤트 - 내 탭
   */
  const handleMyRestore = async (menuType: string, id: string) => {
    const copyState = myListState.find((item) => item.id === id);
    if (copyState) {
      const updatedState = { ...copyState, deleted: false };
      setMyListState((prevState) => prevState.map((item) => (item.id === id ? updatedState : item)));
      menuType === 'plugin' ? await plugin.postPlugin(updatedState) : await prompt.putPrompt(updatedState);
    }
  };

  /**
   * [Func] 즐겨찾기 이벤트
   */
  // const handleBookmark = async (menuType: string, id: string, bookmarked: boolean) => {
  //   const copyState = { ...cardListState.find((item) => item.id === id) };
  //   const isPlugin = menuType === 'plugin';

  //   try {
  //     if (bookmarked) {
  //       // 즐겨찾기 선택시
  //       isPlugin
  //         ? await plugin.postPluginBookmark(copyState.id as string)
  //         : await prompt.postPromptBookmark(copyState.id as string);
  //     } else {
  //       // 즐겨찾기 취소시
  //       isPlugin
  //         ? await plugin.deletePluginBookmark(copyState.id as string)
  //         : await prompt.deletePromptBookmark(copyState.id as string);
  //     }
  //   } catch (error) {
  //     console.error('Error handling bookmark:', error);
  //   }
  // };
  /**
   * [Func] 즐겨찾기 이벤트 - 전체 탭
   */
  const handleAllBookmark = async (menuType: string, id: string, bookmarked: boolean) => {
    const copyState = { ...listState.find((item) => item.id === id) };

    const isPlugin = menuType === 'plugin';

    try {
      if (bookmarked) {
        // 즐겨찾기 선택시
        isPlugin
          ? await plugin.postPluginBookmark(copyState.id as string)
          : await prompt.postPromptBookmark(copyState.id as string);
      } else {
        // 즐겨찾기 취소시
        isPlugin
          ? await plugin.deletePluginBookmark(copyState.id as string)
          : await prompt.deletePromptBookmark(copyState.id as string);
      }
    } catch (error) {
      console.error('Error handling bookmark:', error);
    }
  };

  /**
   * [Func] 즐겨찾기 이벤트 - 즐겨찾기 탭
   */
  const handleBookmaredkBookmark = async (menuType: string, id: string, bookmarked: boolean) => {
    const copyState = { ...bookmarkState.find((item) => item.id === id) };
    const isPlugin = menuType === 'plugin';

    try {
      if (bookmarked) {
        // 즐겨찾기 선택시
        isPlugin
          ? await plugin.postPluginBookmark(copyState.id as string)
          : await prompt.postPromptBookmark(copyState.id as string);
      } else {
        // 즐겨찾기 취소시
        isPlugin
          ? await plugin.deletePluginBookmark(copyState.id as string)
          : await prompt.deletePromptBookmark(copyState.id as string);
      }
    } catch (error) {
      console.error('Error handling bookmark:', error);
    }
  };

  /**
   * [Func] 즐겨찾기 이벤트 - 내 탭
   */
  const handleMyBookmark = async (menuType: string, id: string, bookmarked: boolean) => {
    const copyState = { ...myListState.find((item) => item.id === id) };
    const isPlugin = menuType === 'plugin';

    try {
      if (bookmarked) {
        // 즐겨찾기 선택시
        isPlugin
          ? await plugin.postPluginBookmark(copyState.id as string)
          : await prompt.postPromptBookmark(copyState.id as string);
      } else {
        // 즐겨찾기 취소시
        isPlugin
          ? await plugin.deletePluginBookmark(copyState.id as string)
          : await prompt.deletePromptBookmark(copyState.id as string);
      }
    } catch (error) {
      console.error('Error handling bookmark:', error);
    }
  };

  /**
   * [Func] 되돌리기 이벤트
   */
  // const handleChangeOpenScope = async (menuType: string, id: string, openScope: EOpenScopeType) => {
  //   const copyState = {
  //     ...cardListState.find((item) => item.id === id),
  //     openScope: openScope === EOpenScopeType.Public ? EOpenScopeType.Private : EOpenScopeType.Public,
  //   };

  //   setCardListState((prevState) => prevState.map((item) => (item.id === id ? copyState : item)));

  //   if (menuType === 'plugin') {
  //     await plugin.postPlugin(copyState);
  //   } else {
  //     await prompt.putPrompt(copyState);
  //   }
  // };

  /**
   * [Func] 되돌리기 이벤트
   */
  const handleMyChangeOpenScope = async (menuType: string, id: string, openScope: EOpenScopeType) => {
    // const copyState = {
    //   ...cardListState.find((item) => item.id === id),
    //   openScope: openScope === EOpenScopeType.Public ? EOpenScopeType.Private : EOpenScopeType.Public,
    // };

    const copyState = {
      ...myListState.find((item) => item.id === id),
      openScope: openScope === EOpenScopeType.Public ? EOpenScopeType.Private : EOpenScopeType.Public,
    };
    setMyListState((prevState) => prevState.map((item) => (item.id === id ? copyState : item)));
    menuType === 'plugin' ? await plugin.postPlugin(copyState) : await prompt.putPrompt(copyState);
  };

  return {
    handleMyChangeOpenScope: (menuType: string, id: string, openScope: EOpenScopeType) => {
      handleMyChangeOpenScope(menuType, id, openScope).catch((error) => {
        console.error(error);
      });
    },

    handleAllDelete: (menuType: string, id: string) => {
      handleAllDelete(menuType, id).catch((error) => {
        console.error(error);
      });
    },
    handleBookmaredDelete: (menuType: string, id: string) => {
      handleBookmaredDelete(menuType, id).catch((error) => {
        console.error(error);
      });
    },

    handleMyDelete: (menuType: string, id: string) => {
      handleMyDelete(menuType, id).catch((error) => {
        console.error(error);
      });
    },

    handleMyRemove: (menuType: string, id: string) => {
      handleMyRemove(menuType, id).catch((error) => {
        console.error(error);
      });
    },
    handleMyRestore: (menuType: string, id: string) => {
      handleMyRestore(menuType, id).catch((error) => {
        console.error(error);
      });
    },

    handleAllBookmark: (menuType: string, id: string, bookmarked: boolean) => {
      handleAllBookmark(menuType, id, bookmarked).catch((error) => {
        console.error(error);
      });
    },
    handleBookmaredkBookmark: (menuType: string, id: string, bookmarked: boolean) => {
      handleBookmaredkBookmark(menuType, id, bookmarked).catch((error) => {
        console.error(error);
      });
    },
    handleMyBookmark: (menuType: string, id: string, bookmarked: boolean) => {
      handleMyBookmark(menuType, id, bookmarked).catch((error) => {
        console.error(error);
      });
    },
  };
}
