import React from 'react';
import { Badge, Heading, Link, Text } from '../../../base';
import { Card } from '../../../base/Card';
import { Tooltip } from '../../../Tooltip';
import { IChatMessageReference } from '../../../../libs/models/conversations/ChatMessage';
import noImage from '../../../../assets/etc-icons/img_notfound.png';

export const YouTubeResultCard = ({ video }: { video: IChatMessageReference }) => {
  const formatKorean = (num: number): string => {
    if (num === 0) return '0';

    if (num < 10000) {
      const thousands = num / 1000;
      if (num % 1000 === 0) {
        return `${thousands}천`;
      } else if (num >= 1000) {
        return `${thousands.toFixed(1)}천`;
      }
      return num.toString();
    }

    const units = ['', '만', '억', '조'];
    let result = '';
    let unitIndex = 0;
    let number = num;

    while (number > 0) {
      const segment = number % 10000;
      if (segment > 0) {
        result = segment + units[unitIndex];
      }
      number = Math.floor(number / 10000);
      unitIndex++;
    }

    return result.trim();
  };

  const formatTime = (duration: number): string => {
    const time = duration.toString().split(':');
    if (time[0] == '00') return time[1] + ':' + time[2];
    else return duration.toString();
  };

  return (
    <div className="contents-card youtube">
      <Card
        variant="vertical"
        media={
          <Link href={video.uri}>
            <img
              src={video?.thumbnailUri}
              onError={(e) => {
                e.currentTarget.src = noImage;
              }}
            />
          </Link>
        }
      >
        <React.Fragment>
          <div className="tit">
            <Tooltip position={['bottom', 'right']} theme="inverse" message={video.title}>
              <Heading size="body1" className="card-header">
                <div className="header-content">{video.title ?? 'YouTube Video'}</div>
              </Heading>
            </Tooltip>
          </div>
          <div>
            {video.videoMetaData && (
              <div className="result-meta">
                <Badge className="card-author result-badge" prefixIcon="eyeFill" size="medium">
                  <span className="author"> {formatKorean(video.videoMetaData.viewCount)} </span>
                </Badge>
                <Badge className="card-author result-badge" prefixIcon="chevronLast" size="medium">
                  <span className="author"> {formatTime(video.videoMetaData.duration)} </span>
                </Badge>
              </div>
            )}
          </div>
          <div>
            {video.author && (
              <Link href={video.uri}>
                <Badge className="card-author result-badge" prefixIcon="author" size="medium">
                  <span className="author">{video.author}</span>
                </Badge>
              </Link>
            )}
          </div>

          {/* <Accordion
                        data={[
                            {
                                content: <Text
                                    accent="alternative"
                                    size="caption1"
                                >
                                    {video.timestamps?.map((timestamp, index) => (<div key={index}>
                                        {timestamp}
                                    </div>))}
                                </Text>,
                                icon: '',
                                label: <Text size="label1">타임라인</Text>,
                                value: 'Timestamps'
                            },
                            {
                                content: <Text
                                    accent="alternative"
                                    size="caption1"
                                >
                                    {video.captions}
                                </Text>,
                                icon: '',
                                label: <Text size="label1">설명</Text>,
                                value: 'Captions'
                            }

                        ]}
                        isMultiple={false}
                        defaultValue={[]}
                        onChange={() => { }}
                    /> */}
          <Text className="card-text" accent="alternative" size="caption1">
            {video.content}
          </Text>
        </React.Fragment>
      </Card>
    </div>
  );
};
