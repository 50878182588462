import { useState, useEffect } from 'react';
import { setSelectedModel } from '../../../redux/features/app/aiModelsSlice';
import { useAppDispatch } from '../../../redux/app/hooks';
import { checkMobileKate } from '../../../components/utils/common';
import { Link } from '../../../components/Link';
import { Tag } from '../../../components/Tag';
import { Icon } from '../../../components/Icon';
import { Tooltip } from '../../../components/Tooltip';
import { Button } from '../../../components/Button';
import { Avatar, Empty, Text } from '../../../components/base';
import { IPluginPromptData } from '../../../libs/models/studio/PluginPromptList';
import { AIModelName } from '../../../libs/models/app/AIModel';
import { usePrompt } from '../../../libs/hooks/usePrompt';
import classNames from 'classnames';

interface IChatTemplateListProps {
  setValue?: (value: string) => void;
}

export const ChatTemplateList: React.FC<IChatTemplateListProps> = ({ setValue }) => {
  const dispatch = useAppDispatch();
  const prompt = usePrompt();

  const [isDesktop, setIsDesktop] = useState(!checkMobileKate() || window.innerWidth <= 768);
  const [templates, setTemplates] = useState<IPluginPromptData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    prompt
      .getAllPrompt()
      .then((templates: IPluginPromptData[]) => {
        setTemplates(templates);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setTemplates([]);
        console.error(err);
      });
  }, []);

  useEffect(() => {
    setIsDesktop(window.innerWidth > 768);
  }, []);

  const onPromptClick = (template: IPluginPromptData) => {
    if (setValue) {
      setValue(template.promptTemplate ?? '');
      dispatch(setSelectedModel(template.model ?? AIModelName.GPT_4O_Mini));
    }
  };
  return (
    <>
      {templates.length === 0 ? (
        <Empty
          className={isLoading ? 'ing-skeleton' : ''}
          image="images/ico_load.svg"
          desc={
            <>
              <Text as="strong" weight="semibold">
                즐겨찾기한 프롬프트 템플릿이 없습니다
              </Text>
              <br />
              프롬프트 템플릿 즐겨찾기 시 빠른 활용이 가능해요
            </>
          }
        >
          {isDesktop && (
            <Button
              theme="secondary"
              size="small"
              onClick={() => {
                const options =
                  'location=no,menubar=no,toolbar=yes,scrollbars=no,resizable=yes,width=1600,height=800,left=200,top=200'; // 팝업 창의 크기와 위치 및 설정
                window.open('/makers/template', '_blank', options);
              }}
            >
              프롬프트 템플릿 보러가기
            </Button>
          )}
        </Empty>
      ) : (
        <>
          <div className="template-wrap">
            {templates.map((template, index) =>
              isDesktop ? (
                <div key={index} className="template-item">
                  <Tooltip theme="inverse" message={template.name} className="tooltip-wrap template-item">
                    <Link
                      className="template-item-height"
                      onClick={() => {
                        onPromptClick(template);
                      }}
                    >
                      {isDesktop && template.tags && template.tags.length > 0 && (
                        <Tag key={index}>
                          <Icon name="prompt" />
                          {template.tags[0]}
                        </Tag>
                      )}
                      <div className="title">
                        <strong className="name">{template.name}</strong>
                        {!isDesktop && template.tags && template.tags.length > 0 && (
                          <Tag key={index}>
                            <Icon name="prompt" />
                            {template.tags[0]}
                          </Tag>
                        )}
                        <span className="desc">{template.description}</span>
                      </div>
                    </Link>
                  </Tooltip>
                </div>
              ) : (
                <div key={index} className="model-list-item">
                  <Link
                    className="model-item"
                    onClick={() => {
                      onPromptClick(template);
                    }}
                  >
                    <Avatar src={template.profileUrl ?? ''} label="" />
                    <div className="title">
                      <strong className="name">{template.name}</strong>
                      <Text className="desc">{template.description}</Text>
                    </div>
                  </Link>
                </div>
              ),
            )}
          </div>
          {!isDesktop && (
            <Text size="caption1" className="ds-text-info">
              프롬프트 템플릿 목록 편집은 pc환경에서만 가능해요
            </Text>
          )}
        </>
      )}
      <div className={classNames('template-wrap', !isLoading && 'ing-skeleton')}>
        {Array.from({ length: 6 }).map((_, index) => (
          <div key={index} className="template-item-skeleton"></div>
        ))}
      </div>
    </>
  );
};
