import React from "react";
import { Badge, Button, ButtonArea, Heading, Text } from "../../../base";
import { Card } from "../../../base/Card";
import { IChatMessageReference } from "../../../../libs/models/conversations/ChatMessage";
import { Tooltip } from "../../../Tooltip";

export const AcademicResultCard = ({ academic }: { academic: IChatMessageReference }) => {
    const openLink = () => {
        window.open(academic.uri, academic.title);
    }
    return (
        <div className="contents-card academic">
            <Card variant='vertical'>
                <React.Fragment>
                <div className="tit">
                    <Tooltip position={['bottom', 'right']} theme='inverse' message={academic.title}>
                        <Heading size="body1" className="card-header">
                            <div className="header-content">
                                {academic.title}
                            </div>
                        </Heading>
                    </Tooltip>
                    </div>
                    <div>
                        {academic.author &&
                            <Badge
                                className="card-author result-badge"
                                prefixIcon="author"
                                size="medium"
                            >
                                <span className="author">{academic.author}</span>
                            </Badge>}
                    </div>
                    <div>
                        {academic.publishedDate &&
                            <Badge
                                className="card-publishedDate result-badge"
                                prefixIcon="calendar"
                                size="medium"
                            >
                                {new Date(academic.publishedDate).toLocaleDateString()}
                            </Badge>}
                    </div>
                    <Text
                        className="card-text"
                        accent="alternative"
                        size="caption1"
                    >
                        {academic.content}
                    </Text>
                    <ButtonArea align='full'>
                        <Button suffixIcon="link" variant={'primary'} onClick={openLink}>
                            View Paper
                        </Button>
                    </ButtonArea>

                </React.Fragment>
            </Card>
        </div>
    )
};
