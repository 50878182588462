import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Empty, Select, Text } from '../../components/base';
import { Constants } from '../../Constants';
import { IPluginPromptData } from '../../libs/models/studio/PluginPromptList';
import PluginPromptCard from './PluginPromptCard';
import { v4 as uuidv4 } from 'uuid';

interface PluginPromptAllProps {
  pluginListState: IPluginPromptData[] | undefined;
  promptListState: IPluginPromptData[] | undefined;
  hasMore: boolean;
  loadMore: () => void;
  setFormValues: React.Dispatch<React.SetStateAction<any>>;
  formValues: any;
  totalCount: number;
  menuLabel: string;
  menuType: 'plugin' | 'prompt';
  scrollViewTargetRef: React.RefObject<HTMLDivElement>;
  handleCardClick: (item: IPluginPromptData) => void;
  handleDelete: (menuType: string, id: string) => void;
  handleBookmark: (menuType: string, id: string, bookmarked: boolean) => void;
  activeTabKey: string;
  selectedSort: string;
  handleSort: (value: string | number) => void;
  isLoading: boolean;
  setPluginState: React.Dispatch<React.SetStateAction<IPluginPromptData[]>>;
  setPromptState: React.Dispatch<React.SetStateAction<IPluginPromptData[]>>;
  onBookmarkToggle: (id: string, bookmarked: boolean) => void;
}

const PluginPromptAll: React.FC<PluginPromptAllProps> = ({
  pluginListState = [], // 기본값 빈 배열
  promptListState = [], // 기본값 빈 배열
  hasMore,
  loadMore,
  totalCount,
  // menuLabel,
  menuType,
  scrollViewTargetRef,
  handleCardClick,
  handleDelete,
  // handleBookmark,
  activeTabKey,
  selectedSort,
  handleSort,
  isLoading,
  setPluginState,
  setPromptState,
  onBookmarkToggle,
}) => {
  const [listToRender, setListToRender] = useState<IPluginPromptData[]>([]);

  useEffect(() => {
    if (!isLoading) {
      // isLoading이 false일 때만 리스트 업데이트
      if (menuType === 'plugin') setListToRender(pluginListState);
      else setListToRender(promptListState);
    }
  }, [menuType, pluginListState, promptListState, isLoading]);

  return (
    <>
      <div className="setting-bar">
        <Text size="body1" weight="medium" accent="neutral">
          총 {totalCount}개
        </Text>
        <div className="side">
          <Select
            size="large"
            placeholder="정렬 선택"
            options={Constants.orderbyList}
            value={selectedSort}
            onChange={handleSort}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="studio-card">
          {Array.from({ length: 12 }).map((_, index) => (
            <PluginPromptCard key={index + uuidv4()} menuType={menuType} isLoading={true} />
          ))}
        </div>
      ) : listToRender.length === 0 ? (
        <Empty
          image="images/ico_nodata.png"
          title={menuType === 'plugin' ? '플러그인이 없습니다.' : '프롬프트가 없습니다.'}
        />
      ) : (
        <InfiniteScroll
          loadMore={loadMore}
          initialLoad={false}
          hasMore={hasMore}
          isReverse={false}
          useWindow={false}
          getScrollParent={() => scrollViewTargetRef.current}
        >
          <div className="studio-card">
            {listToRender.map((item) => (
              <PluginPromptCard
                key={item.id + menuType}
                menuType={menuType}
                onDelete={handleDelete}
                // onBookmark={handleBookmark}
                activeTabKey={activeTabKey}
                onCardClick={() => {
                  handleCardClick(item);
                }}
                setPluginState={setPluginState}
                setPromptState={setPromptState}
                onBookmarkToggle={onBookmarkToggle}
                {...item}
              />
            ))}
          </div>
        </InfiniteScroll>
      )}
    </>
  );
};

export default PluginPromptAll;
