import { useState, useRef, useEffect } from 'react';
import {
  Dialog,
  Button,
  TabList,
  TabPanel,
  Heading,
  Text,
  Stack,
  IconButton,
  Checkbox,
  Textarea,
  FileUploader,
} from '../../components/base';
import Image from '../../components/Image';
import classNames from 'classnames';
import { usePrompt } from '../../libs/hooks/usePrompt';
import { IPluginPromptData } from '../../libs/models/studio/PluginPromptList';
import emoji1 from '../../assets/images/emoji01.png';
import emoji2 from '../../assets/images/emoji02.png';
import emoji3 from '../../assets/images/emoji03.png';
import emoji4 from '../../assets/images/emoji04.png';
import emoji5 from '../../assets/images/emoji05.png';
import emoji6 from '../../assets/images/emoji06.png';
import emoji7 from '../../assets/images/emoji07.png';
import emoji8 from '../../assets/images/emoji08.png';
import emoji9 from '../../assets/images/emoji09.png';
import emoji10 from '../../assets/images/emoji10.png';
import emoji11 from '../../assets/images/emoji11.png';
import emoji12 from '../../assets/images/emoji12.png';
import { IconUpload } from '../../assets/icons';

interface AiContProps {
  title?: string;
  suffix?: React.ReactNode;
  error?: string;
  children: React.ReactNode;
}

const AiCont: React.FC<AiContProps> = ({ title = 'title', suffix, error, children }) => {
  return (
    <article className="ai-cont">
      <div className="ai-cont-title">
        <Text as="strong" weight="semibold">
          {title}
        </Text>
        {suffix}
      </div>
      {children}
      {error && <Text variant="error">{error}</Text>}
    </article>
  );
};

interface PluginPromptCreateDialogProps {
  isOpen: boolean;
  onClose: () => void;
  setFormData: React.Dispatch<React.SetStateAction<IPluginPromptData>>;
  formData: IPluginPromptData;
}

const PluginPromptCreateDialog: React.FC<PluginPromptCreateDialogProps> = ({
  isOpen,
  onClose,
  setFormData,
  formData,
}) => {
  const prompt = usePrompt();
  const [dalleImageUrl, setDalleImageUrl] = useState('');
  const [dragging, setDragging] = useState(false);

  // Tab 상태 관리
  const [tabListActiveKey, setTabListActiveKey] = useState<string>('tab-1');
  const handleTabListChange = (key: string) => {
    setTabListActiveKey(key);
  };

  // [TAB-1] 아이콘 선택 관련 상태 및 이벤트 핸들러
  const [selectedEmoji, setSelectedEmoji] = useState<string | null>(null);

  const handleEmojiClick = (emoji: string) => {
    setSelectedEmoji(emoji);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>, emoji: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      setSelectedEmoji(emoji);
    }
  };

  const emojis = [
    {
      id: '1',
      img: 'https://stvmaiwaprodkorcent.blob.core.windows.net/image/studio/emoji01.png',
      displayImage: emoji1,
    },
    {
      id: '2',
      img: 'https://stvmaiwaprodkorcent.blob.core.windows.net/image/studio/emoji02.png',
      displayImage: emoji2,
    },
    {
      id: '3',
      img: 'https://stvmaiwaprodkorcent.blob.core.windows.net/image/studio/emoji03.png',
      displayImage: emoji3,
    },
    {
      id: '4',
      img: 'https://stvmaiwaprodkorcent.blob.core.windows.net/image/studio/emoji04.png',
      displayImage: emoji4,
    },
    {
      id: '5',
      img: 'https://stvmaiwaprodkorcent.blob.core.windows.net/image/studio/emoji05.png',
      displayImage: emoji5,
    },
    {
      id: '6',
      img: 'https://stvmaiwaprodkorcent.blob.core.windows.net/image/studio/emoji06.png',
      displayImage: emoji6,
    },
    {
      id: '7',
      img: 'https://stvmaiwaprodkorcent.blob.core.windows.net/image/studio/emoji07.png',
      displayImage: emoji7,
    },
    {
      id: '8',
      img: 'https://stvmaiwaprodkorcent.blob.core.windows.net/image/studio/emoji08.png',
      displayImage: emoji8,
    },
    {
      id: '9',
      img: 'https://stvmaiwaprodkorcent.blob.core.windows.net/image/studio/emoji09.png',
      displayImage: emoji9,
    },
    {
      id: '10',
      img: 'https://stvmaiwaprodkorcent.blob.core.windows.net/image/studio/emoji10.png',
      displayImage: emoji10,
    },
    {
      id: '11',
      img: 'https://stvmaiwaprodkorcent.blob.core.windows.net/image/studio/emoji11.png',
      displayImage: emoji11,
    },
    {
      id: '12',
      img: 'https://stvmaiwaprodkorcent.blob.core.windows.net/image/studio/emoji12.png',
      displayImage: emoji12,
    },
  ];

  // [TAB-2] AI 이미지 생성 관련 상태 및 이벤트 핸들러
  const [createState, setCreateState] = useState<'notStarted' | 'loading' | 'completed'>('notStarted');
  const [thumbLarger, setThumbLarger] = useState(false);

  interface AiType {
    img: string;
    name: string;
    value: string;
  }

  const aiType: AiType[] = [
    { img: 'images/img_sample_aiNoType.png', name: '미선택', value: '' },
    { img: 'images/img_sample_aiType01.png', name: '사진', value: 'picture' },
    { img: 'images/img_sample_aiType02.png', name: '픽셀아트', value: 'pixelart' },
    { img: 'images/img_sample_aiType03.png', name: '일러스트', value: 'illustration' },
    { img: 'images/img_sample_aiType04.png', name: '3D 모델', value: '3D model' },
  ];

  interface Example {
    img: string;
    text: React.ReactNode;
  }

  const example: Example[] = [
    {
      img: 'images/img_sample_ai01.png',
      text: (
        <>
          3D 스타일,
          <br />
          문서 정리하는 곰돌이
        </>
      ),
    },
    {
      img: 'images/img_sample_ai02.png',
      text: (
        <>
          애니메이션 스타일,
          <br />
          달리는 강아지
        </>
      ),
    },
    {
      img: 'images/img_sample_ai03.png',
      text: (
        <>
          사진 스타일,
          <br />
          일하는 회사원
        </>
      ),
    },
    {
      img: 'images/img_sample_ai04.png',
      text: (
        <>
          미선택,
          <br />
          수채화 스타일의 베트남 풍경
        </>
      ),
    },
  ];

  const [displayedExamples, setDisplayedExamples] = useState<Example[]>([]);

  const getRandomExamples = () => {
    const shuffled = example.sort(() => 0.5 - Math.random());
    const selected = shuffled.slice(0, 3);
    setDisplayedExamples(selected);
  };
  useEffect(() => {
    getRandomExamples();
  }, []);

  const [textValue, setTextValue] = useState('');
  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(event.target.value);
  };

  const [selectedAiType, setSelectedAiType] = useState('');
  const handleAiTypeChange = (value: string) => {
    setSelectedAiType(value);
  };
  const [error, setError] = useState<string | null>(null);

  const handleCreate = async () => {
    try {
      setError(null); // 에러 상태 초기화
      setCreateState('loading');
      const result = await prompt.getDalleImage(
        '스타일 옵션을 우선시하여 이미지를 생성하세요. 스타일 옵션:' + selectedAiType + ' , 이미지 설명:' + textValue,
      );
      setDalleImageUrl(result);

      setCreateState('completed');
    } catch (error) {
      console.error('Error creating image:', error);
      setCreateState('notStarted');
      setError('이미지 생성 중 오류가 발생했습니다. 다시 시도해 주세요.');
    }
  };

  const handleThumbClick = () => {
    setThumbLarger(true);
  };
  const handleThumbKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === 'Enter' || event.key === ' ') setThumbLarger(true);
  };

  // [TAB-3] 사진 업로드 관련 상태 및 이벤트 핸들러
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const handleImageChange = (files: File[]) => {
    if (files.length > 0) {
      // 파일 배열의 첫 번째 파일만 선택
      setSelectedImage(files[0]);
    } else {
      setSelectedImage(null);
    }
  };
  const fileRef = useRef<HTMLInputElement>(null);

  const handleFileInputClick = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleRegister = async () => {
    switch (tabListActiveKey) {
      case 'tab-1':
        setFormData((prev) => ({ ...prev, profileUrl: selectedEmoji as string }));
        break;
      case 'tab-2':
        urlToFile(dalleImageUrl, `${textValue + selectedAiType}.png`)
          .then(async (file) => {
            const result = await prompt.postProfileImg(formData.id ?? '', file);

            setFormData((prev) => ({ ...prev, profileUrl: result.value }));
          })
          .catch((error) => {
            console.error('Error:', error);
          });
        break;
      case 'tab-3':
        if (selectedImage) {
          const newVersion = (formData.profileUrlVersion ?? 0) + 1;
          const result = await prompt.postProfileImg(formData.id ?? '', selectedImage);
          setFormData((prev) => ({ ...prev, profileUrl: result.value, profileUrlVersion: newVersion }));
        }
        break;
    }
    onClose();
  };

  const urlToBlob = async (url: string): Promise<Blob> => {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Failed to fetch image');
    }
    return response.blob();
  };

  const blobToFile = (blob: Blob, fileName: string): File => {
    return new File([blob], fileName, { type: blob.type });
  };

  const urlToFile = async (url: string, fileName: string): Promise<File> => {
    const blob = await urlToBlob(url);
    return blobToFile(blob, fileName);
  };

  return (
    <Dialog
      className="dialog-profiles"
      isOpen={isOpen}
      onClose={onClose}
      buttons={
        <>
          <Button variant="secondary" size="large" onClick={onClose}>
            취소
          </Button>
          {tabListActiveKey === 'tab-2' && createState !== 'completed' ? (
            <Button
              variant="primary"
              size="large"
              onClick={() => {
                void handleCreate();
              }}
              disabled={textValue === '' || createState === 'loading'}
            >
              {createState === 'loading' ? '생성 중...' : '이미지 생성'}
            </Button>
          ) : (
            <Button
              variant="primary"
              size="large"
              onClick={() => {
                void handleRegister();
              }}
            >
              적용하기
            </Button>
          )}
        </>
      }
    >
      <Heading as="h2" size="title3" weight="semibold">
        프로필 이미지 등록
      </Heading>

      <TabList activeTabKey={tabListActiveKey} onTabChange={handleTabListChange}>
        {/* 이모지 아이콘 선택 UI */}
        <TabPanel controlsId="tab-1" label="아이콘 선택" icon="smileFill">
          <div className="profiles-emoji" role="listbox">
            {emojis.map((emoji) => (
              <span
                key={emoji.id}
                className={classNames('emoji-item', selectedEmoji === emoji.img ? 'is-selected' : '')}
                role="option"
                aria-selected={selectedEmoji === emoji.img}
                tabIndex={0}
                onClick={() => {
                  handleEmojiClick(emoji.img);
                }}
                onKeyDown={(event) => {
                  handleKeyDown(event, emoji.img);
                }}
                aria-label={`Select ${emoji.id}`}
              >
                {/* {emoji} */}
                <img src={emoji.displayImage} className="emoji-img" alt={`프로필 이미지 ${emoji.id}번`} />
              </span>
            ))}
          </div>
        </TabPanel>

        {/* AI 이미지 생성 관련 UI*/}
        <TabPanel controlsId="tab-2" label="AI 이미지 생성" icon="starFill">
          {/* 생성 중 */}
          {createState === 'loading' ? (
            <div className="works-loading">
              <span className="loading" aria-hidden="true"></span>
              <Text>
                이미지 생성 중입니다. <br />
                조금만 기다려 주세요
              </Text>
            </div>
          ) : (
            <div className="profiles-ai">
              {/* 생성 후*/}
              {createState === 'completed' && (
                <div className={classNames('ai-thumb', thumbLarger && 'ai-thumb--full')}>
                  <span
                    className="ai-thumb-img"
                    tabIndex={thumbLarger ? undefined : 0}
                    role={thumbLarger ? undefined : 'button'}
                    onClick={!thumbLarger ? handleThumbClick : undefined}
                    onKeyDown={
                      !thumbLarger
                        ? (event) => {
                            handleThumbKeyDown(event);
                          }
                        : undefined
                    }
                  >
                    <img src={dalleImageUrl} />
                  </span>
                  {thumbLarger && (
                    <IconButton
                      name="closeFill"
                      className="ai-thumb-close"
                      onClick={() => {
                        setThumbLarger(false);
                      }}
                    >
                      닫기
                    </IconButton>
                  )}
                </div>
              )}

              {/* 공통 */}
              <AiCont title="이미지 묘사" error={error ? error : ''}>
                <Textarea
                  variant="filled"
                  size="xlarge"
                  placeholder="원하는 이미지를 묘사해 주세요 (스타일, 주제, 색감, 분위기 등)"
                  value={textValue}
                  onChange={handleTextChange}
                />
              </AiCont>
              <AiCont title="스타일">
                <Stack className="grey-box">
                  {aiType.map((item, idx) => (
                    <Checkbox
                      name="aiType"
                      checked={selectedAiType === item.value}
                      onChange={() => {
                        handleAiTypeChange(item.value);
                      }}
                      className="ai-item"
                      key={idx}
                    >
                      <span className="ai-item-img">
                        <Image url={item.img && item.img !== '' ? item.img : 'img_sample_aiNoType.png'} />
                      </span>
                      <Text as="span" size="caption1" weight="medium">
                        {item.name}
                      </Text>
                    </Checkbox>
                  ))}
                </Stack>

                {createState === 'completed' && (
                  <Button
                    onClick={() => {
                      setCreateState('notStarted');
                    }}
                    variant="normal"
                    className="btn-recreate"
                  >
                    {' '}
                    다시 만들기{' '}
                  </Button>
                )}
              </AiCont>

              {/* 생성 전 */}
              {createState === 'notStarted' && (
                <AiCont
                  title="[예시] 이렇게 묘사해 보세요!"
                  suffix={
                    <IconButton name="refresh" onClick={getRandomExamples}>
                      새로고침
                    </IconButton>
                  }
                >
                  <Stack className="grey-box">
                    {displayedExamples.map((item, idx) => (
                      <div className="exam-item" key={item.img || idx}>
                        <Image url={item.img} className="exam-item-img" />
                        <Text size="caption1" weight="medium">
                          {item.text}
                        </Text>
                      </div>
                    ))}
                  </Stack>
                </AiCont>
              )}
            </div>
          )}
        </TabPanel>

        {/* 사진 업로드 UI */}
        <TabPanel controlsId="tab-3" label="사진 직접 등록" icon="pictureFill">
          <div className="profiles-img">
            {!selectedImage ? (
              <FileUploader
                allowDrop
                allowFileType={['.jpg', '.jpeg', '.png', '.gif']}
                onChange={handleImageChange}
                uploadMsg={
                  <>
                    <IconUpload size={40} variant={dragging ? 'primary' : 'normal'} />
                    <Text size="body3" weight="medium">
                      내 PC에서 파일을 첨부하거나
                      <br />
                      마우스로 문서를 드래그하여 넣어주세요.
                    </Text>
                    <Text size="caption1" accent="alternative">
                      *권장 규격 280x280, 최대 크기 1MB 입니다.{' '}
                    </Text>
                  </>
                }
                onDrag={(bool: boolean) => {
                  setDragging(bool);
                }}
                buttonProps={{ variant: dragging ? 'primary' : 'secondary', text: '내 PC 파일 업로드' }}
              />
            ) : (
              <Stack vertical className="grey-box">
                <input
                  type="file"
                  aria-hidden="true"
                  className="blind"
                  ref={fileRef}
                  onChange={handleFileChange}
                  accept=".jpg,.jpeg,.png,.gif"
                />
                {/* <Avatar className="upload-img" src={URL.createObjectURL(selectedImage)} label="프로필 이미지" /> */}

                <span className="upload-img">
                  <img src={URL.createObjectURL(selectedImage)} alt="프로필 이미지" />
                </span>
                <Button variant="outline" onClick={handleFileInputClick}>
                  다른 사진 등록
                </Button>
              </Stack>
            )}
          </div>
        </TabPanel>
      </TabList>
    </Dialog>
  );
};

export default PluginPromptCreateDialog;
