import { Icon } from '../../components/Icon';
import { IChatMessageProgress } from '../../libs/models/conversations/ChatMessage';

interface ITypingIndicatorProps {
  progress?: IChatMessageProgress;
  step: number;
}

export const TypingIndicator: React.FC<ITypingIndicatorProps> = ({ progress }) => {
  return (
    <>
      {progress == null ? (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ verticalAlign: 'middle' }}>
          <circle className={`icon-loading`} cx="10" cy="10" r="5.25" />
        </svg>
      ) : (
        progress.items.map((step, index) => (
          <div className="msg-indicator" key={index}>
            <p>{step.message}</p>
            {progress.totalStage != 0 && <span><b className="txt-point">{index + 1}</b> / {progress.totalStage}단계</span>}
            <span className="msg-icon">
              {!step.isCompleted ? (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ verticalAlign: 'middle' }}>
                  <circle className={`icon-loading`} cx="10" cy="10" r="5.25" />
                </svg>
              ) : (
                <Icon name="check" />
              )}
            </span>
          </div>
        ))
      )}
    </>
  );
};
