import clsx from 'clsx';
import React from 'react';

interface CardProps {

    variant: 'vertical'|'horiontal',
    media?: React.ReactNode,
    children?: React.ReactNode,
    float?: React.ReactNode
}

export const Card: React.FC<CardProps> = ({ variant = 'vertical', media, children, float, ...props }) => {
  return (
    <div className={clsx('dsx-Card', variant && `dsx-Card--${variant}`)} {...props}>
      {media && (
        <div className="dsx-Card-media">
          {media}
        </div>
      )}
      {children && (
        <div className="dsx-Card-content">
          {children}
        </div>
      )}      
      {float && (
        <div className="dsx-Card-float">
          {float}
        </div>
      )}
    </div>
  );
};

