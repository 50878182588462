import React, { useEffect, useState } from 'react';
import { Dialog, Avatar, Heading, Text, Badge, Stack, ButtonArea, Button, IconButton } from '../../../components/base';
import { IPluginPromptData } from '../../../libs/models/studio/PluginPromptList';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { showAlertToast } from '../../../redux/features/app/appSlice';
import { AlertType } from '../../../libs/models/AlertType';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { checkDownloadUrl } from '../../chat/chat-history/ChatHistoryTextContent';

interface PromptViewerProps {
  promptData: IPluginPromptData;
  isOpen: boolean;
  onClose: () => void;
  onBookmarkToggle: (id: string, bookmarked: boolean) => void;
}

const PromptViewer: React.FC<PromptViewerProps> = ({ promptData, isOpen, onClose, onBookmarkToggle }) => {
  // if (!promptData) return null;
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state: RootState) => state.users);
  const {
    id,
    name,
    tags,
    model,
    bookmarkUserCount,
    profileUrl,
    promptTemplate,
    promptTemplateResponse,
    description,
    createdTime,
    updatedTime,
    createdMember,
    bookmarkUserIds,
    deleted,
  } = promptData;

  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bookmarkCount, setBookmarkCount] = useState(0);
  const [isToggling, setIsToggling] = useState(false); // API 호출 중인지 여부

  useEffect(() => {
    if (bookmarkUserIds) {
      setIsBookmarked(!!bookmarkUserIds.find((userId) => userId === user.userId));
      setBookmarkCount(Number(bookmarkUserCount));
    }
  }, [bookmarkUserIds, user.userId, bookmarkUserCount]);

  useEffect(() => {
    // 북마크 상태가 변경될 때마다 count를 업데이트
    if (isBookmarked) {
      setBookmarkCount((prevCount) => prevCount + 1);
    } else {
      setBookmarkCount((prevCount) => prevCount - 1);
    }
  }, [isBookmarked]);

  const handleBookmarkToggle = () => {
    if (isToggling) return; // 이미 API 호출 중이면 함수 종료

    setIsToggling(true); // API 호출 시작
    const localBookmark = !isBookmarked;
    setIsBookmarked(localBookmark);
    onBookmarkToggle(id as string, localBookmark);

    // API 호출 후 1초 후에 다시 활성화
    setTimeout(() => {
      setIsToggling(false);
    }, 1000);
  };

  const wrapPlaceholderWithSpan = (text: string | undefined) => {
    if (text) {
      const regex = /\[\[(.*?)\]\]/g;
      const parts = text.split(regex); // split by [[ ]]

      return parts.map((part, index) => {
        // Odd indexed parts are the ones inside [[ ]]
        if (index % 2 === 1) {
          return (
            <span key={index} className="dsx-color-primary">
              [[{part}]]
            </span>
          );
        }
        // Even indexed parts are normal text
        return part;
      });
    } else {
      return '';
    }
  };

  const sharedPrompt = () => {
    const url = `${window.location.origin}/makers/template?sharedId=${promptData.id}`;

    navigator.clipboard
      .writeText(url)
      .then(() => {
        dispatch(
          showAlertToast({
            altMessage: 'URL이 복사되었습니다.',
            altType: AlertType.Info,
          }),
        );
      })
      .catch((err) => {
        console.error('클립보드 복사 실패: ', err);
      });
  };

  const copyPrompt = () => {
    const popup = window.opener as Window;
    if (popup) {
      popup.setPrompt(promptData);
      //popup.close();
      window.close();
    } else {
      if (!promptTemplate) return;

      navigator.clipboard
        .writeText(promptTemplate)
        .then(() => {
          dispatch(
            showAlertToast({
              altMessage: '프롬프트 템플릿이 복사되었습니다.',
              altType: AlertType.Info,
            }),
          );
        })
        .catch((err) => {
          console.error('클립보드 복사 실패: ', err);
        });
    }
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className="studio-read">
      <div className="read-detail">
        <div className="det-head">
          <Avatar  size="5" src={profileUrl} label="프로필 이미지" />
          <div className="d-text">
            <Heading as="h2" size="title3" weight="semibold">
              {name}
            </Heading>
            <Stack>
              <Badge variant="tint" size="large" accent="primary">
                {model}
              </Badge>
            </Stack>
          </div>
          {!deleted && (
            <Stack className="d-utils">
              <Button
                variant="outline"
                prefixIcon={isBookmarked ? 'bookmarkFill' : 'bookmark'}
                onClick={handleBookmarkToggle}
              >
                {bookmarkCount}
              </Button>
              <Button variant="outline" iconOnly="share" onClick={sharedPrompt}>
                공유하기
              </Button>
            </Stack>
          )}
        </div>
        <div className="det-body">
          <p className="d-desc">{description}</p>
          <div className="dsx-title-bar">
            <Heading size="body2" weight="semibold">
              프롬프트
            </Heading>
            <IconButton name="copy" onClick={copyPrompt} disabled={!!deleted}>
              복사
            </IconButton>
          </div>
          <div className="dsx-cont-box prompt-data">{wrapPlaceholderWithSpan(promptTemplate)}</div>
          <div className="d-keyword">
            {tags?.map((tag: string, index: number) => (
              <Badge key={index} variant="outlined" size="xlarge" accent="primary">
                # {tag}
              </Badge>
            ))}
          </div>
        </div>
        <div className="det-foot">
          <ul className="d-date">
            <li>
              <span className="tit">생성</span>
              <span className="con">{moment(createdTime as Date).format('YYYY-MM-DD')}</span>
            </li>
            <li>
              <span className="tit">업데이트</span>
              <span className="con">{moment(updatedTime as Date).format('YYYY-MM-DD')}</span>
            </li>
          </ul>
          <div className="d-user">
            <span className="dsx-avatar-info">
              <Avatar
                size="1"
                src={`https://gspaceapi.kt.co.kr/upload/profile/${createdMember?.userId}.jpg`}
                label=""
              />{' '}
              {createdMember?.name}
            </span>
          </div>
        </div>
        <ButtonArea align="full">
          <Button size="large" variant="outline" onClick={onClose}>
            닫기
          </Button>
          <Button size="large" variant="primary" onClick={copyPrompt} disabled={!!deleted}>
            프롬프트 사용하기
          </Button>
        </ButtonArea>
      </div>
      {/* 우픅 chat 미리보기 */}
      <div className="read-preview">
        <div className="view-info view-info--neutral">
          <Heading size="body3" weight="semibold">
            프롬프트 템플릿 테스트 사용 예시
          </Heading>
          <Text size="label2">프롬프트 템플릿 복사 후 [[]] 괄호 안 쪽을 입력하여 활용해주세요</Text>
        </div>
        <div className="view-chat">
          <section className="chat-history">
            <div className="chat-history-item">
              <div className="chat-message">
                <div className="msg-profile">
                  <Avatar src={`https://gspaceapi.kt.co.kr/upload/profile/${user.userId}.jpg`} label="" />
                  <strong className="name">{user.displayName}</strong>
                </div>
                <div className="msg-content">{promptTemplate}</div>
              </div>
              <div className="chat-message">
                <div className="msg-profile">
                  <Avatar  src={profileUrl} label="프롬프트 프로필 이미지2" />
                  <strong className="name">{name}</strong>
                </div>
                <div className="msg-content">
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    // rehypePlugins={[rehypeHighlight]}
                    components={{
                      a: ({ children, ...props }) => {
                        if (props.href?.includes('http')) {
                          props.target = '_blank';
                          props.rel = 'noopener noreferrer';
                        }
                        const { isDownloadUrl, url } = checkDownloadUrl(props.href);
                        if (isDownloadUrl && url) {
                          props.target = '_blank';
                          props.rel = 'noopener noreferrer';
                          props.href = url;
                          props.className = 'msg-caution ds-link no-click';
                          children = children?.toString().replace('[', '').replace(']', '');
                        }
                        return <a {...props}>{children}</a>;
                      },
                      // 'del' 태그를 span 태그로 대체하여 취소선을 제거
                      del: ({ ...props }) => <span>{props.children}</span>,
                      table: ({ ...props }) => (
                        <div className="msg-table">
                          <table>{props.children}</table>
                        </div>
                      ),
                    }}
                  >
                    {promptTemplateResponse?.replace(/\\n/g, '<br>')}
                  </ReactMarkdown>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="read-close">
          <IconButton name="closeLarge" className="dsx-Dialog-close" onClick={onClose}>
            닫기
          </IconButton>
        </div>
      </div>
    </Dialog>
  );
};

export default PromptViewer;
