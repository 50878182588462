import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Constants } from '../../Constants';
import PageTitle from '../../components/PageTitle';
import { Icon, Button, ChipGroup, Input, TabList, TabPanel } from '../../components/base';
import { usePlugin } from '../../libs/hooks/usePlugin';
import { usePluginActions } from '../../libs/hooks/usePluginActions';
import { usePrompt } from '../../libs/hooks/usePrompt';
import { IPluginPromptData, IPluginPromptParam } from '../../libs/models/studio/PluginPromptList';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import PluginPromptAll from './PluginPromptAll';
import PluginPromptBookmark from './PluginPromptBookmark';
import PluginPromptMy from './PluginPromptMy';
import PluginViewer from './plugin/PluginViewer';
import PromptViewer from './prompt/PromptViewer';
import { showAlertToast } from '../../redux/features/app/appSlice';
import { AlertType } from '../../libs/models/AlertType';

interface IPluginPromptHomeProps {
  menuType: 'plugin' | 'prompt';
}

const studioContentMap = {
  plugin: {
    h1: 'Plugin Studio',
    title: '<strong>플러그인</strong> 활용 및 <strong>플러그인</strong> 배포하기',
    desc: '플러그인 설치를 통해 업무 가능성을 극대화 해보세요.',
    search: '유용한 플러그인을 검색해 보세요',
    regist: '플러그인 등록',
  },
  prompt: {
    h1: 'Prompt Template Studio',
    title: '<strong>프롬프트 템플릿</strong> 활용 및 등록',
    desc: '프롬프트 템플릿을 통해 내가 원하는 답변에 맞는 AI 응답을 생성해 보세요.',
    search: '프롬프트 템플릿을 검색해 보세요',
    regist: '프롬프트 등록',
  },
};

const PluginPromptHome: React.FC<IPluginPromptHomeProps> = ({ menuType }) => {
  const studioContent = studioContentMap[menuType];
  const plugin = usePlugin();
  const prompt = usePrompt();
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const scrollViewTargetRef = React.useRef<HTMLDivElement>(null);

  const [selectedTab, setSelectedTab] = useState<{ key: string; label: string }>({
    label: '전체',
    key: 'All',
  });

  const [activeCategory, setActiveCategory] = useState('all'); // 카테고리 chip state - 전체선택시에 소문자 all 로 설정한다.
  const [activeMyCategory, setActiveMyCategory] = useState('chip1');
  const [showDeleted, setShowDeleted] = useState(false);
  const categoryOptions = [
    { value: 'chip1', label: '전체' },
    { value: 'chip2', label: '전체공개' },
    { value: 'chip3', label: '나만 보기' },
    { value: 'chip4', label: '임시저장' },
    { value: 'chip5', label: '최근 삭제 목록' },
  ];

  const RegistButton = () => {
    const handleMainButtonClick = () => {
      handleLinkCreate();
    };

    const handleAionuClick = () => {
      // Aionu 등록 로직
      handleAionuLinkCreate();
    };

    useEffect(() => {
      const handleEscKey = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          if (isPluginViewerOpen) {
            setIsPluginViewerOpen(false);
          }
          if (isPromptViewerOpen) {
            setIsPromptViewerOpen(false);
          }
        }
      };

      window.addEventListener('keydown', handleEscKey);

      // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
      return () => {
        window.removeEventListener('keydown', handleEscKey);
      };
    }, [isPluginViewerOpen, isPromptViewerOpen]);

    return (
      <div className="regist-button-container add-button">
        {menuType === 'plugin' && process.env.REACT_APP_AIONU === 'TRUE' && (
          <Button size="large" variant="normal" prefixIcon="plus" onClick={handleAionuClick}>
            에이전트 등록
          </Button>
        )}
        <Button size="large" variant="normal" prefixIcon="plus" onClick={handleMainButtonClick}>
          {studioContent.regist}
        </Button>
      </div>
    );
  };

  const [activeTabKey, setActiveTabKey] = useState('All');

  const [listState, setListState] = useState<IPluginPromptData[]>([]);
  const [bookmarkState, setBookmarkState] = useState<IPluginPromptData[]>([]);
  const [myListState, setMyListState] = useState<IPluginPromptData[]>([]);
  const [todayCount, setTodayCount] = useState<number>(0);

  const [allTotalCount, setAllTotalCount] = useState<number>(0);
  const [bookMarktotalCount, setBookMarkTotalCount] = useState<number>(0);
  const [myTotalCount, setMyTotalCount] = useState<number>(0);

  const [selectedSort, setSelectedSort] = useState<string>('UpdatedTime');

  const menuLabel = menuType === 'plugin' ? '플러그인' : '프롬프트';
  const isPromptDisabled = menuType === 'prompt' && todayCount >= 5;
  const isPluginDisabled = menuType === 'plugin' && todayCount >= 5;

  const isDisabled = isPromptDisabled || isPluginDisabled;
  const count = menuType === 'prompt' ? todayCount : todayCount;

  const [menuTypeState, setMenuTypeState] = useState('');

  const [isDeleted] = useState<boolean>(false); // 최근 삭제
  const [searchValue, setSearchValue] = useState<string>(''); // 검색어

  const [continuationToken, setContinuationToken] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPromptViewerOpen, setIsPromptViewerOpen] = useState(false);
  const [isPluginViewerOpen, setIsPluginViewerOpen] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState<IPluginPromptData>({});
  const [selectedPlugin, setSelectedPlugin] = useState<IPluginPromptData>({});

  const [searchParams] = useSearchParams();
  const id = searchParams.get('sharedId');

  const {
    handleMyChangeOpenScope,
    handleAllBookmark,
    handleBookmaredkBookmark,
    handleMyBookmark,
    handleAllDelete,
    handleBookmaredDelete,
    handleMyDelete,
    handleMyRemove,
    handleMyRestore,
  } = usePluginActions(listState, bookmarkState, myListState, setListState, setBookmarkState, setBookmarkState);
  const { categoryList } = useAppSelector((state: RootState) => state.category);
  const { user } = useAppSelector((state: RootState) => state.users);

  const [formValues, setFormValues] = useState<IPluginPromptParam>({
    categoryId: null,
    openScope: null,
    createdStatus: null,
    versionStatus: null,
    isDeleted: false,
    orderBy: 'UpdatedTime',
    orderDirection: 'desc',
    isBookmark: null,
    count: 20,
    continuationToken: null,
    keyword: null,
  });

  // 241230 "전체" 카테고리 추가
  const newCategoryList = [...categoryList];
  const exists = newCategoryList.some((item) => item.value === 'all' && item.label === '전체');
  if (!exists) {
    newCategoryList.unshift({ value: 'all', label: '전체' });
    // newCategoryList를 설정하는 모양으로 상태 업데이트를 해야함.
  }
  /**
   * [Func] 탭 클릭 [전체, 즐겨찾기, 내플러그인]
   * @param key
   */
  const handleTabSelect = (key: string) => {
    setIsLoading(true);
    handleReset();
    setActiveTabKey(key);
    const tabItem = Constants.tabListItems.find((item) => item.key === key);
    if (tabItem) {
      setSelectedTab(tabItem);
    } else {
      setSelectedTab({ label: '전체', key: 'All' });
    }
  };

  /**
   * [Func] 카테고리 리스트 클릭
   * @param value
   */
  const handleButtonClick = (value: string | string[]) => {
    setIsLoading(true);
    resetList();
    setContinuationToken(null);
    if (selectedTab.key === 'MyPlugin') {
      if (value === 'chip2') {
        setFormValues((prev) => ({ ...prev, isDeleted: false, openScope: 0, versionStatus: null }));
      } else if (value === 'chip3') {
        setFormValues((prev) => ({ ...prev, isDeleted: false, openScope: 1, versionStatus: null }));
      } else if (value === 'chip4') {
        setFormValues((prev) => ({ ...prev, isDeleted: false, openScope: null, versionStatus: 0 }));
      } else if (value === 'chip1') {
        setFormValues((prev) => ({ ...prev, isDeleted: false, openScope: null, versionStatus: null }));
      } else if (value === 'chip5') {
        setFormValues((prev) => ({ ...prev, isDeleted: true, openScope: null, versionStatus: null }));
      }
      setActiveMyCategory(value as string);
      setShowDeleted(showDeleted);
    } else {
      // setFormValues((prev) => ({
      //   ...prev,
      //   categoryId: value === 'all' ? null : (value as string),
      // }));
      setFormValues((prev) => ({
        ...prev,
        categoryId: value === 'all' ? null : (value as string),
        continuationToken: null,
      }));
    }
    setActiveCategory(value as string);
  };

  /**
   * [Func] 프롬프트 등록갯수 조회
   */
  const callTodayCount = useCallback(async () => {
    try {
      const res = menuType === 'plugin' ? await plugin.getPluginTodayCount(3) : await prompt.getPromptTodayCount(3);
      setTodayCount(res.totalCount);
    } catch (error) {
      console.error('Error occurred in callCategory:', error);
    }
  }, []);

  /**
   * [Func] All 리스트 조회
   */
  const callList = useCallback(async () => {
    try {
      if (listState.length === 0) setIsLoading(true);

      const res =
        menuType === 'plugin'
          ? await plugin.getPluginList({ ...formValues, openScope: 0 })
          : await prompt.getPromptList({ ...formValues, openScope: 0 });

      setListState((prev) => {
        const combinedList = [...prev, ...res.data];

        // 1. Map 생성 및 중복 제거
        const uniqueMap = new Map(combinedList.map((item) => [item.id, item]));
        // 2. Map의 값들만 배열로 변환
        return Array.from(uniqueMap.values());
      });

      setContinuationToken(res.continuationToken);
      setAllTotalCount(res.totalCount);
    } catch (error) {
      console.error('Error occurred in callCategory:', error);
    } finally {
      setIsLoading(false);
    }
  }, [formValues]);

  /**
   * [Func] 즐겨찾기 조회
   */
  const callBookmark = useCallback(async () => {
    try {
      if (bookmarkState.length === 0) setIsLoading(true);

      const res =
        menuType === 'plugin'
          ? await plugin.getPluginBookmark({ ...formValues, openScope: 0 })
          : await prompt.getPromptBookmark({ ...formValues, openScope: 0 });

      setBookmarkState((prev) => {
        const combinedList = [...prev, ...res.data];

        // 1. Map 생성 및 중복 제거
        const uniqueMap = new Map(combinedList.map((item) => [item.id, item]));
        // 2. Map의 값들만 배열로 변환
        return Array.from(uniqueMap.values());
      });

      setContinuationToken(res.continuationToken);
      setBookMarkTotalCount(res.totalCount);
    } catch (error) {
      console.error('Error occurred in callCategory:', error);
    } finally {
      setIsLoading(false);
    }
  }, [formValues]);

  /**
   * [Func] 내 리스트 조회
   */
  const callMyList = useCallback(async () => {
    try {
      if (myListState.length === 0) setIsLoading(true);
      const res =
        menuType === 'plugin' ? await plugin.getMyPluginList(formValues) : await prompt.getMyPromptList(formValues);

      setMyListState((prev) => {
        const combinedList = [...prev, ...res.data];

        // 1. Map 생성 및 중복 제거
        const uniqueMap = new Map(combinedList.map((item) => [item.id, item]));
        // 2. Map의 값들만 배열로 변환
        return Array.from(uniqueMap.values());
      });
      setContinuationToken(res.continuationToken);
      setMyTotalCount(res.totalCount);
    } catch (error) {
      console.error('Error occurred in callCategory:', error);
    } finally {
      setIsLoading(false);
    }
  }, [formValues]);

  /**
   * [Func] 배포하기 링크 연결
   */
  const handleLinkCreate = () => {
    if (isDisabled) return;
    menuTypeState === 'plugin' ? navigation('/makers/plugin/pluginCreate') : navigation('/makers/prompt/promptCreate');
  };

  /**
   * [Func] Aionu 배포하기 링크 연결
   */
  const handleAionuLinkCreate = () => {
    if (isDisabled) return;
    navigation('/makers/plugin/AionuCreate');
  };

  // [Func] 초기화 함수
  const handleReset = () => {
    resetList();
    setActiveCategory('all'); // 카테고리 chip 초기화
    setActiveTabKey('All'); // Tab 초기화
    setActiveMyCategory('chip1'); // 내플러그인 전체 초기화
    setShowDeleted(false);
    setSearchValue(''); // 검색어 초기화
    setSelectedSort('UpdatedTime'); // 정렬 초기화
    setFormValues({
      categoryId: null,
      openScope: null,
      createdStatus: null,
      versionStatus: null,
      isDeleted: false,
      orderBy: 'UpdatedTime',
      orderDirection: 'desc',
      isBookmark: null,
      count: 20,
      continuationToken: null,
      keyword: null,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    handleReset();
    setMenuTypeState(menuType);
  }, [menuType]);

  useEffect(() => {
    if (selectedTab.key === 'All') {
      void callList();
    }
    if (selectedTab.key === 'Bookmark') {
      void callBookmark();
    }
    if (selectedTab.key === 'MyPlugin') {
      void callTodayCount();
      void callMyList();
    }
  }, [selectedTab, isDeleted, formValues, showDeleted]);

  /**
   * [Func] Enter key event
   * @param event
   */
  const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (searchValue.length < 2) {
        dispatch(showAlertToast({ altMessage: '검색어는 2자 이상 입력해주세요', altType: AlertType.Info }));
        return;
      }
      // 검색어가 2자 이상인지 확인
      resetList();
      setFormValues((prev) => ({
        ...prev,
        keyword: searchValue,
        continuationToken: null,
      }));
    }
  };

  useEffect(() => {
    setHasMore(continuationToken !== null);
    setIsLoading(false);
  }, [continuationToken]);

  /**
   * [Func] 스크롤 페이징 처리
   * @returns
   */
  const loadMore = () => {
    if (!hasMore || isLoading) return;
    try {
      setIsLoading(true);
      setFormValues((prev) => ({ ...prev, continuationToken }));
      setHasMore(false);
      setIsLoading(false);
    } catch {}
  };

  const handleCloseViewer = () => {
    setIsPromptViewerOpen(false);
    setIsPluginViewerOpen(false);
  };

  /**
   * Bookmark시 이벤트 함수
   * @param id
   * @param bookmarked
   */
  const handleBookmarkViewer = (id: string, bookmarked: boolean) => {
    if (menuType === 'prompt') {
      setListState((prevState) => {
        return prevState.map((item) => {
          if (item.id === id) {
            // bookmarked가 true일 때는 ID 추가
            if (bookmarked) {
              // user.userId가 배열에 존재하지 않을 때만 추가
              if (!item.bookmarkUserIds?.includes(user.userId)) {
                setSelectedPrompt(item);
                return {
                  ...item,
                  bookmarkUserIds: item.bookmarkUserIds ? [...item.bookmarkUserIds, user.userId] : [user.userId],
                  bookmarkUserCount: item.bookmarkUserCount ? item.bookmarkUserCount + 1 : 1,
                };
              }
            } else {
              // bookmarked가 false일 때는 ID 제거
              if (item.bookmarkUserIds?.includes(user.userId)) {
                setSelectedPrompt(item);
                return {
                  ...item,
                  bookmarkUserIds: item.bookmarkUserIds.filter((userId) => userId !== user.userId),
                  bookmarkUserCount: item.bookmarkUserCount ? Math.max(item.bookmarkUserCount - 1, 0) : 0,
                };
              }
            }
          }
          return item;
        });
      });

      if (activeTabKey === 'All') {
        handleAllBookmark(menuType, id, bookmarked);
      } else if (activeTabKey === 'Bookmark') {
        handleBookmaredkBookmark(menuType, id, bookmarked);
      } else {
        handleMyBookmark(menuType, id, bookmarked);
      }
    } else {
      setListState((prevState) => {
        return prevState.map((item) => {
          if (item.id === id) {
            // bookmarked가 true일 때는 ID 추가
            if (bookmarked) {
              // user.userId가 배열에 존재하지 않을 때만 추가
              if (!item.bookmarkUserIds?.includes(user.userId)) {
                setSelectedPlugin(item);
                return {
                  ...item,
                  bookmarkUserIds: item.bookmarkUserIds ? [...item.bookmarkUserIds, user.userId] : [user.userId],
                  bookmarkUserCount: item.bookmarkUserCount ? item.bookmarkUserCount + 1 : 1,
                };
              }
            } else {
              // bookmarked가 false일 때는 ID 제거
              if (item.bookmarkUserIds?.includes(user.userId)) {
                setSelectedPlugin(item);
                return {
                  ...item,
                  bookmarkUserIds: item.bookmarkUserIds.filter((userId) => userId !== user.userId),
                  bookmarkUserCount: item.bookmarkUserCount ? Math.max(item.bookmarkUserCount - 1, 0) : 0,
                };
              }
            }
          }
          return item;
        });
      });

      if (activeTabKey === 'All') {
        handleAllBookmark(menuType, id, bookmarked);
      } else if (activeTabKey === 'Bookmark') {
        handleBookmaredkBookmark(menuType, id, bookmarked);
      } else {
        handleMyBookmark(menuType, id, bookmarked);
      }
    }
  };

  /*
   * CARD 삭제시, 그리드의 count 바로 반영
   * handleAllDeleteWithCount > 전체탭
   * handleBookmaredDeleteWithCount > 즐겨찾기탭
   * handleMyDeleteWithCount > 나의탭
   */
  const handleAllDeleteWithCount = (menuType: string, id: string) => {
    handleAllDelete(menuType, id);
    setAllTotalCount((prev) => prev - 1);
  };

  const handleBookmaredDeleteWithCount = (menuType: string, id: string) => {
    handleBookmaredDelete(menuType, id);
    setBookMarkTotalCount((prev) => prev - 1);
  };

  const handleMyDeleteWithCount = (menuType: string, id: string) => {
    handleMyDelete(menuType, id);
    setMyTotalCount((prev) => prev - 1);
  };

  const handleCardClick = (PluginPromptData: IPluginPromptData) => {
    if (menuType === 'prompt') {
      if (PluginPromptData.versionStatus === 'Draft' && !PluginPromptData.deleted) {
        navigation(`/makers/prompt/promptCreate?id=${PluginPromptData.id}`);
      } else {
        setSelectedPrompt({ ...PluginPromptData });
        setIsPromptViewerOpen(true);
      }
    } else {
      if (PluginPromptData.versionStatus === 'Draft' && !PluginPromptData.deleted) {
        navigation(`/makers/plugin/pluginCreate?id=${PluginPromptData.id}`);
      } else {
        setSelectedPlugin({ ...PluginPromptData });
        setIsPluginViewerOpen(true);
      }
    }
  };

  const resetList = () => {
    setListState([]); // carList 초기화
    setBookmarkState([]);
    setMyListState([]);
  };

  const handleSort = (value: string | number) => {
    setIsLoading(true);
    resetList();
    setFormValues((prev) => ({
      ...prev,
      orderBy: value as string,
      orderDirection: 'desc',
    }));
    setSelectedSort(value as string);
  };

  useEffect(() => {
    const fetchPluginDetails = async () => {
      try {
        if (id) {
          const result = menuType === 'plugin' ? await plugin.getPlugin(id) : await prompt.getPrompt(id);
          if (result.deleted) return;

          if (menuType === 'plugin') {
            setSelectedPlugin(result);
            setIsPluginViewerOpen(true);
          } else {
            setSelectedPrompt(result);
            setIsPromptViewerOpen(true);
          }
        }
      } catch (error) {
        console.error(`Error fetching ${menuType} details:`, error);
      }
    };
    void fetchPluginDetails();
  }, [id, menuType]);

  return (
    <main className="page-area" ref={scrollViewTargetRef}>
      <h1 className="blind">{studioContent.h1}</h1>
      <div className="page-inner">
        <PageTitle title={studioContent.title} desc={studioContent.desc} />
        <div className="studio-search">
          <Input
            className="search-input"
            placeholder={studioContent.search}
            slotBefore={<Icon name="search" />}
            clearable
            value={searchValue}
            onKeydown={handleKeyDown}
            onChange={handleSearchValueChange}
          />
        </div>
        <div className="studio-content">
          <TabList
            variant="text"
            // items={Constants.tabListItems}
            activeTabKey={activeTabKey}
            onTabChange={handleTabSelect}
          >
            {/****** 전체 ******/}
            <TabPanel controlsId={'All'} label="전체">
              <div className="category-bar">
                <ChipGroup
                  variant="filled"
                  options={newCategoryList}
                  value={activeCategory}
                  onChange={handleButtonClick}
                />
                <div className="side">
                  <RegistButton />
                </div>
              </div>
              <PluginPromptAll
                pluginListState={listState}
                promptListState={listState}
                hasMore={hasMore}
                loadMore={loadMore}
                setFormValues={setFormValues}
                formValues={formValues}
                totalCount={allTotalCount}
                menuLabel={menuLabel}
                menuType={menuType}
                scrollViewTargetRef={scrollViewTargetRef}
                handleCardClick={handleCardClick}
                handleDelete={handleAllDeleteWithCount}
                handleBookmark={handleAllBookmark}
                activeTabKey={activeTabKey}
                selectedSort={selectedSort}
                handleSort={handleSort}
                isLoading={isLoading}
                setPluginState={setListState}
                setPromptState={setListState}
                onBookmarkToggle={handleBookmarkViewer}
              />
            </TabPanel>
            {/****** 즐겨찾기 ******/}
            <TabPanel controlsId={'Bookmark'} label="즐겨찾기">
              <div className="category-bar">
                <ChipGroup
                  variant="filled"
                  options={newCategoryList}
                  value={activeCategory}
                  onChange={handleButtonClick}
                />
                <div className="side">
                  <RegistButton />
                </div>
              </div>
              <PluginPromptBookmark
                bookmarkPluginState={bookmarkState}
                bookmarkPromptState={bookmarkState}
                hasMore={hasMore}
                loadMore={loadMore}
                setFormValues={setFormValues}
                formValues={formValues}
                totalCount={bookMarktotalCount}
                menuLabel={menuLabel}
                menuType={menuType}
                scrollViewTargetRef={scrollViewTargetRef}
                handleCardClick={handleCardClick}
                handleDelete={handleBookmaredDeleteWithCount}
                handleBookmark={handleBookmaredkBookmark}
                activeTabKey={activeTabKey}
                selectedSort={selectedSort}
                handleSort={handleSort}
                isLoading={isLoading}
                setPluginState={setBookmarkState}
                setPromptState={setBookmarkState}
                onBookmarkToggle={handleBookmarkViewer}
              />
            </TabPanel>
            {/****** 내플러그인 ******/}
            <TabPanel controlsId={'MyPlugin'} label={`내 ${menuLabel}`}>
              <div className="category-bar">
                <ChipGroup
                  variant="filled"
                  options={categoryOptions}
                  value={activeMyCategory}
                  onChange={handleButtonClick}
                />
                <div className="side">
                  <RegistButton />
                </div>
              </div>
              <PluginPromptMy
                myPluginState={myListState}
                myPromptState={myListState}
                hasMore={hasMore}
                loadMore={loadMore}
                setFormValues={setFormValues}
                formValues={formValues}
                totalCount={myTotalCount}
                menuLabel={menuLabel}
                menuType={menuType}
                scrollViewTargetRef={scrollViewTargetRef}
                handleCardClick={handleCardClick}
                handleRestore={handleMyRestore}
                handleDelete={handleMyDeleteWithCount}
                handleRemove={handleMyRemove}
                handleBookmark={handleMyBookmark}
                handleChangeOpenScope={handleMyChangeOpenScope}
                activeTabKey={activeTabKey}
                selectedSort={selectedSort}
                handleSort={handleSort}
                isDisabled={isDisabled}
                count={count}
                isLoading={isLoading}
                setPluginState={setMyListState}
                setPromptState={setMyListState}
                onBookmarkToggle={handleBookmarkViewer}
              />
            </TabPanel>
          </TabList>
        </div>
      </div>
      <PromptViewer
        promptData={selectedPrompt}
        isOpen={isPromptViewerOpen}
        onClose={handleCloseViewer}
        onBookmarkToggle={handleBookmarkViewer}
      />
      <PluginViewer
        pluginData={selectedPlugin}
        isOpen={isPluginViewerOpen}
        onClose={handleCloseViewer}
        onBookmarkToggle={handleBookmarkViewer}
      />
    </main>
  );
};

export default PluginPromptHome;
