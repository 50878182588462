import { useChat } from '../../libs/hooks/useChat';
import { FC, useEffect, useState } from 'react';
import { IChatKeyword } from '../../libs/models/conversations/ChatKeyword';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { setSelectedPlugin } from '../../redux/features/app/pluginsSlice';
import { ChatMessageType } from '../../libs/models/conversations/ChatMessage';
import { RootState } from '../../redux/app/store';
import { IAskVariables } from '../../libs/semantic-kernel/model/Ask';
import { setSelectedModel } from '../../redux/features/app/aiModelsSlice';
import { AIModelName } from '../../libs/models/app/AIModel';
import { ChatKeyword } from './ChatKeyword';
import { ChatVisual } from './ChatVisual';
import { IChatVisual } from '../../libs/models/users/ChatVisual';
import { checkMobileKate } from '../../components/utils/common';

export interface GetResponseOptions {
  messageType: ChatMessageType;
  value: string;
  chatId: string;
  model: string;
  plugin?: string;
  kernelArguments?: IAskVariables[];
  processPlan?: boolean;
  webSearchOn: boolean;
}

interface ChatInsightProps {
  onSubmit: (options: GetResponseOptions) => void;
  isInsightLoading: boolean;
  setIsInsightLoading: (value: boolean) => void;
}

export const ChatInsight: FC<ChatInsightProps> = ({ onSubmit, isInsightLoading, setIsInsightLoading }) => {
  const chat = useChat();
  const dispatch = useAppDispatch();
  const { selectedId } = useAppSelector((state: RootState) => state.conversations);

  const [keywordList, setChatKeywordList] = useState<IChatKeyword[]>();
  const [visual, setChatVisual] = useState<IChatVisual | null>(null);
  const [isDisabled, setisDisabled] = useState<boolean>(false);
  const [isDesktop, setIsDesktop] = useState(!checkMobileKate() || window.innerWidth <= 768);

  useEffect(() => {
    setIsDesktop(window.innerWidth > 768);

    setIsInsightLoading(true);
    setisDisabled(false);
    void loadInsight();
  }, []);

  useEffect(() => {
    if (isDesktop) {
      void loadChatVisual();
    }
  }, [isDesktop]);

  const loadInsight = async () => {
    await Promise.all([loadChatVisual(), loadChatKeyword()]);
  };

  const loadChatKeyword = async () => {
    try {
      const result = await chat.getChatKeywords();
      setChatKeywordList(result);
    } catch (error) {
      console.error('Error loading chat keywords:', error); // 에러를 콘솔에 로깅
    }
  };

  const loadChatVisual = async () => {
    try {
      const result = await chat.getChatVisual();
      setChatVisual(result ?? null);
    } catch (error) {
      console.error('Error loading chat visual:', error); // 에러를 콘솔에 로깅
    } finally {
      setIsInsightLoading(false);
    }
  };

  const sendMessage = (item: IChatKeyword) => {
    if (item.pluginId) dispatch(setSelectedPlugin(item.pluginId));
    dispatch(setSelectedModel(item.modelName as string));
    setisDisabled(true);
    onSubmit({
      value: item.content,
      messageType: ChatMessageType.ChatMessage,
      chatId: selectedId,
      model: item.modelName as AIModelName,
      plugin: item.pluginId,
      webSearchOn: item.pluginId == 'Plugin:AiSearch',
    });
  };

  return (
    <div className="chat-insight">
      {isInsightLoading ? (
        // <!-- 스켈레톤 로딩 -->
        <div className="skeleton-loading">
          <div className="skeleton-visual"></div>
          <div className="skeleton-title"></div>
          <div className="skeleton-desc"></div>
          <div className="skeleton-keyword">
            <span className="item"></span>
            <span className="item"></span>
            <span className="item"></span>
            <span className="item"></span>
            <span className="item"></span>
            <span className="control"></span>
          </div>
        </div>
      ) : (
        <>
          {visual && <ChatVisual data={visual} />}
          <ChatKeyword
            data={keywordList}
            isDisabled={isDisabled}
            sendMessage={sendMessage}
            loadChatKeyword={() => {
              void loadInsight();
            }}
          />
        </>
      )}
    </div>
  );
};
