import { useRef, useEffect } from 'react';
import clsx from 'clsx';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { A11y, Autoplay, Navigation, Pagination } from 'swiper/modules';
import { IconButton } from './IconButton';

interface CarouselProps {
  variant?: string;
  slides: React.ReactNode[];
  slidesPerView?: number | 'auto';
  autoplay?: boolean;
  className?: string;
}

export const Carousel: React.FC<CarouselProps> = ({
  variant,
  slides = [],
  slidesPerView = 'auto',
  autoplay = false,
  className,
  ...props
}) => {
  const swiperRef = useRef<SwiperRef>(null);
  const navigationNextRef = useRef(null);
  const navigationPrevRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current.swiper;
      if (swiperInstance.params.navigation && typeof swiperInstance.params.navigation === 'object') {
        swiperInstance.params.navigation.nextEl = navigationNextRef.current;
        swiperInstance.params.navigation.prevEl = navigationPrevRef.current;
      }

      swiperInstance.navigation.init();
      swiperInstance.navigation.update();
    }
  }, []);

  const modules = [A11y, Autoplay, Navigation, Pagination];

  return (
    <div className={clsx('dsx-Carousel', variant && `dsx-Carousel--${variant}`, className)} {...props}>
      <Swiper
        ref={swiperRef}
        modules={modules}
        loop={false}
        spaceBetween={24}
        slidesPerView={slidesPerView}
        centeredSlides={false}
        allowTouchMove={true}
        autoplay={
          autoplay
            ? {
                delay: 5000,
                pauseOnMouseEnter: true, // 마우스 오버 시 일시정지
              }
            : false
        }
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        pagination={{
          clickable: true,
        }}
      >
        {slides.map((slideContent, index) => (
          <SwiperSlide key={index} virtualIndex={2}>{slideContent}</SwiperSlide>
        ))}
      </Swiper>
      <IconButton ref={navigationPrevRef} name="prev" className="dsx-Carousel-prev">
        Previous
      </IconButton>
      <IconButton ref={navigationNextRef} name="next" className="dsx-Carousel-next">
        Next
      </IconButton>
    </div>
  );
};
